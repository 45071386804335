import React from 'react';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

import { colors, breakpoints } from '../../constants/css';

const WithWave = styled.div`
  height: 500px;
  margin-top: 300px;
  background: url('/assets/images/Waves/from-bottom-2.svg') no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > * {
    width: 100%;
  }
`;

const PersonImage = styled.img`
  margin-left: 9%;
  vertical-align: bottom;

  @media(max-width: ${ breakpoints.xl }) {
    max-width: 350px;
  }

  @media(max-width: ${ breakpoints.lg }) {
    margin-left: 2%;
  }

  @media(max-width: ${ breakpoints.md }) {
    display: none;
  }
`;

const ListGroupItem = styled(ListGroup.Item)`
  background: unset;
  border: none;
  padding: .5rem 1rem;
  border-radius: 5px;
  font-size: 1.3rem;

  &.hover:before,
  &.hover:after {
    content: "";
    position: absolute;
    bottom: 5px;
    width: 0px;
    height: 5px;
    margin: 0 0 0;
    transition: all 0.2s ease-in-out;;
    transition-duration: 0.5s;
    opacity: 0;
    left: 1rem;
    background-color: ${ colors.fushia };
  }

  &.hover:hover {
    cursor: pointer;
    &:before,
    &:after {
      width: 80%;
      opacity: 1;
    }
  }

  @media(max-width: ${ breakpoints.sm }) {
    padding: .5rem;
  }

  @media(min-width: ${ breakpoints.lg }) {
    padding: .75rem 1.25rem;
  }
`;

const Footer = props => (
  <WithWave showwave={ props.showwave }>
    <div className="d-flex align-items-end">
      <PersonImage src="/assets/images/Home/person-on-suitcase.svg" />
      <div className="mx-auto d-flex">
        <ListGroup className="flex-row">
          <ListGroupItem className="text-white">
            Useful links
          </ListGroupItem>
          <div className="d-flex flex-column">
            { [{
              link: '/',
              label: 'Home'
            }, {
              link: '/meetings',
              label: 'Meetings'
            }, {
              link: '/feedbacks',
              label: 'Feedbacks'
            }, {
              link: '/they-trust-us',
              label: 'They trust us'
            }, {
              link: '/contact-us',
              label: 'Contact us'
            }].map(x => (
              <ListGroupItem key={ x.link } to={ x.link } className="hover">
                <a className="text-reset text-decoration-none" href={ x.link }>{ x.label }</a>
              </ListGroupItem>
            )) }
          </div>
        </ListGroup>
      </div>
    </div>
    <hr />
    <p className="text-center">© Acelys 2021</p>
  </WithWave>
);

export default Footer;
