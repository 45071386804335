import React from 'react';
import { RiFile2Fill } from 'react-icons/ri';
import styled from 'styled-components';

const StyledFigure = styled.figure`
  display: inline-block;

  a {
    display: inline-block;
    font-size: 4em;
    margin: .5rem;
    margin-bottom: 0;
  }

  div {
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
  }

  figcaption {
    width: 100px;
    font-size: 0.8em;
    font-style: italic;
    word-break: break-all;
    text-align: center;
    margin: 0 .5rem 0 .5rem;
  }
`;

const DocumentThumb = ({ src, fileName }) => (
  <StyledFigure>
    <a href={ src } title={ fileName } target="_blank" rel="noreferrer">
      <div>
        <RiFile2Fill />
      </div>
    </a>
    <figcaption>{ fileName }</figcaption>
  </StyledFigure>
);

export default DocumentThumb;
