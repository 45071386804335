import React from 'react';
import { connect } from 'react-redux';

import { MemberDetails } from '../';
import { useUpdateUserAvatar, useResetPasswordToken } from '../../../http';

const MyProfile = ({ userId, userAbsoluteId }) => {
  const changePictureMutation = useUpdateUserAvatar();
  const resetPasswordMutation = useResetPasswordToken();

  return <MemberDetails
    userId={ userId }
    userAbsoluteId = { userAbsoluteId }
    title="My profile"
    canChangePicture={ true }
    changePictureMutation={ changePictureMutation }
    canResetPassword={ true }
    resetPasswordMutation={ resetPasswordMutation }
    canEdit={ true } />;
};

const mapStateToProps = state => {
  return {
    userId: state.auth.id,
    userAbsoluteId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(MyProfile);
