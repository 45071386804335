import { useQuery } from 'react-query';

import http from '../helpers/http';
import endpoints from './endpoints';

const getBusinessPartners = () => http.get(`${ endpoints.BusinessPartners }?pagination=false`);

const useGetBusinessPartners = () => useQuery('business_partners', () => getBusinessPartners(), { select: x => x['hydra:member'] });

export {
  useGetBusinessPartners
};
