import { connect } from 'react-redux';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import roles from '../../../constants/roles';
import dateHelper from '../../../helpers/date';
import GuardedElement from '../../../hoc/GuardedElement/GuardedElement';
import { useGetEvents, useGetUserEvents } from '../../../http';
import Loader from '../../UI/Loader/Loader';
import RoundedImage from '../../UI/RoundedImage/RoundedImage';
import ModalRoundedImage from '../../UI/ModalRoundedImage/ModalRoundedImage';
import MeetingMediaObject from '../MeetingMediaObject/MeetingMediaObject';
import Carousel from 'react-elastic-carousel';
import DocumentThumb from '../../UI/DocumentThumb/DocumentThumb';
import MeetingMedias from '../MeetingMedias/MeetingMedias';
import { RiMapPin2Fill, RiSearchLine } from 'react-icons/ri';

const MeetingTitle = styled.h2`
  margin-top: -20px;
`;

const MeetingMediasWithMargin = styled(MeetingMedias)`
  margin-bottom: .5rem;
  margin-top: -10px;
`;

const MeetingRow = styled(Row)`
  background-color: #ffffffd0;
  border-radius: 20px;
  padding: 1.5rem;
  position: relative;
  z-index: auto;
`;

const BiggerLink = styled(Link)`
  position: absolute;
  right: 1.5rem;
  font-size: 1.5rem;
  z-index: 1;
`;

const DivWithWave = styled.div`
  background: url('/assets/images/Waves/from-right.svg') no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: -500px;
  width: 800px;
  height: 800px;
  opacity: 0.6;
  z-index: -1;
`;

const isAttending = (eventId, userEvents) => {
  return userEvents
    .map(x => x.event['@id'])
    .includes(eventId);
};

const Meetings = ({ isAdmin, userId }) => {
  const { isLoading, data: meetings = [] } = useGetEvents();
  const { isLoadingUserEvents, data: userEvents = [] } = useGetUserEvents(userId);

  return (
    <>
      <h1 className="text-uppercase w-100 d-flex flex-row mb-5">
        <div className="bordered d-inline-block">Meetings</div>
        <GuardedElement roles={ [roles.admin] }>
          <Link to="/meetings/add" className="ml-auto align-self-end">
            <Button className="btn-small btn-rounded btn-text-light btn-secondary"> Add new meeting </Button>
          </Link>
        </GuardedElement>
      </h1>
      { isLoading || isLoadingUserEvents
        ? <div className="text-center"><Loader /></div>
        : meetings.map(meeting => (
          <MeetingRow key={ meeting.id } className="mt-5">
            { new Date(meeting.startDate).getTime() > Date.now() && !isAttending(meeting['@id'], userEvents) &&
              <BiggerLink to="/contact-us">
                <Button className="btn btn-secondary">REGISTRATION</Button>
              </BiggerLink>
            }
            <Col lg="6" md="12">
              <Row>
                <Col sm="4" className="text-center d-flex align-items-center">
                  <RoundedImage src={ meeting.picture } alt={ meeting.title } />
                </Col>
                <Col sm="8">
                  <MeetingTitle className="bordered d-inline-block">
                    { meeting.title }
                    { (isAdmin || isAttending(meeting['@id'], userEvents)) &&
                      <Link to={ `/meetings/${ meeting.id }` } className="ml-2 small">
                        <RiSearchLine />
                      </Link>
                    }
                  </MeetingTitle>
                  <h3>{ meeting.subTitle }</h3>
                  <span>{ dateHelper.formatEnglishDateWithOrdinal(new Date(meeting.startDate)) } to { dateHelper.formatEnglishDateWithOrdinal(new Date(meeting.endDate)) }</span>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <span className="font-weight-bold">Location</span>
                </Col>
                <Col sm="8">
                  <span>
                    { [meeting.address, meeting.zipCode, meeting.city, meeting.country].join(' ') }
                    <RiMapPin2Fill className="ml-2" />
                  </span>
                </Col>
              </Row>
              <Row>
                { isAttending(meeting['@id'], userEvents) &&
                  <Col sm="12">
                    <MeetingMediasWithMargin
                      bordered={ true }
                      actions={ false }
                      title="Documents"
                      meetingId={ meeting.id }
                      medias={ meeting.documents }
                      component={ <DocumentThumb /> } />
                  </Col>
                }
              </Row>
            </Col>
            <Col lg="6" md="12">
              <MeetingMediasWithMargin
                bordered={ true }
                actions={ false }
                title="Photos" />
              <Carousel itemsToShow={ 2 } itemPadding={ [10, 80] }>
                { meeting.pictures.map(x =>
                  <div key={ x.id } className="position-relative">
                    <MeetingMediaObject
                      mediaId={ x.id }
                      mediaComponent={ <ModalRoundedImage /> } />
                  </div>
                ) }
              </Carousel>
            </Col>
          </MeetingRow>
        )) }
        { !isLoading && <DivWithWave /> }
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.roles.includes(roles.admin),
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Meetings);
