import React, { useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import FileSaver from 'file-saver';

import Loader from '../Loader/Loader';
import GlobalFilter from './GlobalFilter';
import { RiArrowDownSLine, RiArrowLeftSLine, RiArrowRightSLine, RiArrowUpSLine, RiFileExcel2Fill, RiSkipBackLine, RiSkipForwardLine } from 'react-icons/ri';
import styled from 'styled-components';
import { colors } from '../../../constants/css';

const ExportIcon = styled(RiFileExcel2Fill)`
  font-size: 1.5em;
`;

const StyledTable = styled(Table)`
  width: 100%;

  thead {
    background-color: white;
  }

  thead th {
    background-color: ${ colors.whiteCool };
    text-align: center;
    padding: 1rem;
    border: none !important;
    color: ${ colors.black };

    :first-of-type {
      border-bottom-left-radius: 20px;
    }
    :last-of-type {
      border-bottom-right-radius: 20px;
    }
  }

  tbody td {
    background-color: white;
    color: ${ colors.gray };
    padding: 1rem;

    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tbody tr.selected td {
    background-color: ${ colors.blue }10;
    color: ${ colors.black };
  }

  tbody tr:hover td {
    color: ${ colors.black };
  }

  tbody tr:first-of-type td {
    border-top: none !important;
  }

  tbody tr:last-of-type td {
    :first-of-type {
      border-bottom-left-radius: 20px;
    }
    :last-of-type {
      border-bottom-right-radius: 20px;
    }
  }

  tbody tr td:not(:last-of-type) {
    border-right: 1px solid #dee2e6;
  }

  tfoot {
    *, *:hover {
      background-color: unset !important;
      border: none !important;
      color: ${ colors.black }
    }

    *.disabled, *.disabled * {
      color: ${ colors.gray } !important;
    }

    *.active * {
      color: ${ colors.black } !important;
      background-color: #e4e4eb !important;
      border-radius: 5px;
    }
  }
`;

const exportHandler = (selectedElements, exportFn, setIsExporting) => {
  setIsExporting(true);
  exportFn(selectedElements)
    .then(res => {
      const blob = new Blob([res], { type: 'text/csv' });

      FileSaver.saveAs(blob, 'export.csv');
    })
    .finally(() => setIsExporting(false));
};

const checkAccessor = (row, selectedElements, setSelectedElements, selectRowId) => {
  return (
    <div className="text-center">
      <input type="checkbox"
      id={ `checkbox_${ row.id }` }
      checked={ selectedElements.find(x => x === selectRowId(row)) }
      onChange={ ev => {
        if (ev.target.checked) {
          setSelectedElements([...selectedElements, selectRowId(row)]);
        } else {
          setSelectedElements(selectedElements.filter(x => x !== selectRowId(row)));
        }
      } } />
    </div>);
};

const CustomTable = ({
  searchPlaceholder,
  showSearch = true,
  pageSize = 10,
  isLoading,
  selectable = false,
  selectRowId = row => row.original.id,
  selectedComponent = () => {},
  selectedComponentProps = () => {},
  data = [],
  additionalFieldsToWatch = [],
  additionalComponent,
  exportable,
  exportFn,
  exportSelectId = row => row['@id'],
  sortBy = {},
  ...props
}) => {
  const columns = props.columns.slice();

  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  if (exportable) {
    columns.push({
      id: 'checkbox',
      Header: (
        <div className="text-center">
          <ExportIcon
            className={ selectedElements.length === 0 || isExporting ? 'text-muted' : 'text-primary' }
            onClick={ () => selectedElements.length > 0 && !isExporting && exportHandler(selectedElements, exportFn, setIsExporting) }
            title="Export selected users as Excel file" />
            <div>
              <span className="small mr-1 pointer text-primary" onClick={ () => setSelectedElements([]) }>None</span>|
              <span className="small ml-1 pointer text-primary" onClick={ () => setSelectedElements(data.map(exportSelectId)) }>All</span>
            </div>
        </div>),
      accessor: row => checkAccessor(row, selectedElements, setSelectedElements, exportSelectId),
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  };

  const tableInstance = useTable({
    columns: useMemo(() => columns, [...additionalFieldsToWatch, selectedElements, isExporting]),
    data: useMemo(() => data, [...additionalFieldsToWatch, data, selectedElements]),
    initialState: {
      pageSize: pageSize,
      pageIndex: 0,
      sortBy: [
        sortBy,
      ]
    },
  },
  useGlobalFilter,
  useSortBy,
  usePagination);

  return (
    <>
      { showSearch &&
        <div className="d-flex justify-content-between mb-2">
          <GlobalFilter
            globalFilter={ tableInstance.state.globalFilter }
            setGlobalFilter={ tableInstance.setGlobalFilter }
            disabled={ isLoading || isExporting }
            placeholder={ searchPlaceholder }
          />
          { additionalComponent && React.cloneElement(additionalComponent) }
        </div>
      }
      <StyledTable responsive size={ props.size || 'sm' } { ...tableInstance.getTableProps() }>
        <thead>
          { tableInstance.headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr key={ headerGroupIndex } { ...headerGroup.getHeaderGroupProps() }>
              { headerGroup.headers.map((header, headerIndex) => (
                <th key={ headerIndex } { ...header.getHeaderProps(header.getSortByToggleProps()) }>
                  { header.render('Header') }
                  {
                    header.isSorted
                      ? header.isSortedDesc ? <RiArrowDownSLine className="ml-1" /> : <RiArrowUpSLine className="ml-1" />
                      : ''
                  }
                </th>
              )) }
            </tr>
          )) }
        </thead>
        { isLoading || isExporting
          ? <tbody>
              <tr>
                <td colSpan={ columns.length } className="text-center">
                  <Loader />
                </td>
              </tr>
          </tbody>
          : tableInstance.rows.length === 0
            ? <tbody>
                <tr>
                  <td colSpan={ columns.length } className="text-center">
                    No data to display
                  </td>
                </tr>
            </tbody>
            : <>
                <tbody { ...tableInstance.getTableBodyProps() }>
                  { tableInstance.page.map((row, rowIndex) => {
                    tableInstance.prepareRow(row);
                    return (
                      <tr key={ rowIndex } { ...row.getRowProps() }
                        className={ selectRowId(row) === selectedRowId ? 'selected' : undefined }
                        style={ selectable ? { cursor: 'pointer' } : undefined }
                        onClick={ () => { selectable && setSelectedRowId(selectRowId(row)); } }>
                        { row.cells.map((cell, cellIndex) => (
                          <td key={ cellIndex } title={ cell.title } { ...cell.getCellProps() }>{ cell.render('Cell') }</td>
                        )) }
                      </tr>
                    );
                  }) }
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={ columns.length }>
                      <div className="d-flex flex-row">
                        <div >{ tableInstance.pageCount } page(s) / { tableInstance.rows.length } element(s)</div>
                        <Pagination className="justify-content-center mb-0 ml-auto">
                          <Pagination.First disabled={ !tableInstance.canPreviousPage } onClick={ () => tableInstance.gotoPage(0) }>
                            <RiSkipBackLine />
                          </Pagination.First>

                          <Pagination.Prev disabled={ !tableInstance.canPreviousPage } onClick={ () => tableInstance.previousPage() }>
                            <RiArrowLeftSLine /> Previous
                          </Pagination.Prev>

                          { tableInstance.state.pageIndex > 0 ? <Pagination.Item onClick={ () => tableInstance.previousPage() }>{ tableInstance.state.pageIndex }</Pagination.Item> : null }
                          <Pagination.Item active>{ tableInstance.state.pageIndex + 1 }</Pagination.Item>
                          { tableInstance.state.pageIndex < (tableInstance.pageCount - 1) ? <Pagination.Item onClick={ () => tableInstance.nextPage() }>{ tableInstance.state.pageIndex + 2 }</Pagination.Item> : null }

                          <Pagination.Next disabled={ !tableInstance.canNextPage } onClick={ () => tableInstance.nextPage() }>
                            Next <RiArrowRightSLine />
                          </Pagination.Next>

                          <Pagination.Last disabled={ !tableInstance.canNextPage } onClick={ () => tableInstance.gotoPage(tableInstance.pageCount - 1) }>
                            <RiSkipForwardLine />
                          </Pagination.Last>
                        </Pagination>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </>
        }
      </StyledTable>
      { selectedRowId &&
        React.cloneElement(selectedComponent, {
          ...selectedComponentProps(selectedRowId)
        })
      }
    </>
  );
};

export default CustomTable;
