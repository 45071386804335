import { connect } from 'react-redux';

const GuardedElement = props => {
  if (!props.isAuthenticated) {
    return null;
  }

  if (props.roles && !props.roles.every(x => props.userRoles.includes(x))) {
    return null;
  }

  return props.children;
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userRoles: state.auth.roles || []
  };
};

export default connect(mapStateToProps)(GuardedElement);
