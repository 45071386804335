import http from '../helpers/http';
import endpoints from './endpoints';

const getContactFromEmail = email => http.get(`${ endpoints.Contacts }?email=${ email }`);

const useGetContactFromEmail = (onSuccess = () => {}) => email => getContactFromEmail(email).then(onSuccess);

export {
  useGetContactFromEmail,
};
