import { Field, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RiPencilLine } from 'react-icons/ri';

import roles from '../../../constants/roles';
import dateHelper from '../../../helpers/date';
import GuardedElement from '../../../hoc/GuardedElement/GuardedElement';
import { useCreateMedia, useGetEvent, usePatchEvent, useUpdateEvent } from '../../../http';
import CheckBoxField from '../../UI/CheckBoxField/CheckBoxField';
import DocumentThumb from '../../UI/DocumentThumb/DocumentThumb';
import Loader from '../../UI/Loader/Loader';
import RoundedImage from '../../UI/RoundedImage/RoundedImage';
import MeetingMedias from '../MeetingMedias/MeetingMedias';
import MeetingParticipants from '../MeetingParticipants/MeetingParticipants';

const MeetingDetails = ({ meetingId }) => {
  const { isLoading, data: meeting = {} } = useGetEvent(meetingId);

  const patchEventMutation = usePatchEvent();
  const sendPictureMutation = useCreateMedia(media => {
    patchEventMutation.mutate({ id: meetingId, pictures: [...meeting.pictures.map(p => p['@id']), media['@id']] });
  });
  const sendDocumentMutation = useCreateMedia(media => {
    patchEventMutation.mutate({ id: meetingId, documents: [...meeting.documents.map(d => d['@id']), media['@id']] });
  });

  const updateEventMutation = useUpdateEvent();

  const formChangeMeetingStatus = useFormik({
    initialValues: {
      id: meeting.id,
      enabled: meeting.enabled
    },
    enableReinitialize: true,
    onSubmit: values => updateEventMutation.mutateAsync(values)
  });

  if (meetingId === null) {
    return null;
  }

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return (
    <>
      <h1 className="text-uppercase"><div className="bordered d-inline-block">Event</div> details</h1>
      <Row>
        <Col sm="12" md="4" className="text-center">
          <RoundedImage src={ meeting.picture } alt={ meeting.title } width={ '200px' } height={ '200px' } />
        </Col>
        <Col sm="12" md="8">
          <h2 className="d-flex flex-row align-items-center">
            { meeting.title }
            <GuardedElement roles={ [roles.admin] }>
              <FormikProvider value={ formChangeMeetingStatus }>
                  <Form className="d-flex ml-auto" onSubmit={ formChangeMeetingStatus.handleSubmit }>
                    { formChangeMeetingStatus.isSubmitting
                      ? <Loader size="sm" />
                      : <Field
                          name="enabled"
                          type="switch"
                          title="Enable/Disable the meeting"
                          component={ CheckBoxField }
                          onChange={ () => { formChangeMeetingStatus.setFieldValue('enabled', !formChangeMeetingStatus.values.enabled); formChangeMeetingStatus.submitForm(); } }
                          top={ 10 } />
                    }
                  </Form>
                </FormikProvider>
              <Link to={ `/meetings/${ meetingId }/edit` } className="ml-2 small">
                <RiPencilLine title="Edit" />
              </Link>
            </GuardedElement>
          </h2>
          <h3>{ meeting.subTitle }</h3>
          <p>{ dateHelper.formatEnglishDateWithOrdinal(new Date(meeting.startDate)) } to { dateHelper.formatEnglishDateWithOrdinal(new Date(meeting.endDate)) }</p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm="3" className="font-weight-bold">Location</Col>
        <Col sm="9">{ meeting.address } { meeting.zipCode } { meeting.city } { meeting.country }</Col>
      </Row>
      <Row>
        <Col lg="6" md="12">
          <MeetingMedias
            className="mb-2"
            title="Photos"
            meetingId={ meeting.id }
            medias={ meeting.pictures }
            addMutation={ sendPictureMutation }
            acceptFiles=".gif,.jpg,.jpeg,.png"
            component={ <RoundedImage /> } />
        </Col>
        <Col lg="6" md="12">
          <MeetingMedias
            className="mb-2"
            title="Documents"
            meetingId={ meeting.id }
            medias={ meeting.documents }
            addMutation={ sendDocumentMutation }
            acceptFiles=".pdf"
            component={ <DocumentThumb /> } />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg="10" className="offset-lg-1">
          <MeetingParticipants
            meetingId={ meeting.id }
            canChangeParticipants = { new Date() < new Date(meeting.startDate) } />
        </Col>
      </Row>
    </>
  );
};

export default MeetingDetails;
