import { useQuery } from 'react-query';

import http from '../helpers/http';
import endpoints from './endpoints';

const getBusinessAreas = () => http.get(`${ endpoints.BusinessAreas }?pagination=false`);

const useGetBusinessAreas = () => useQuery('business_areas', () => getBusinessAreas(), { select: x => x['hydra:member'] });

export {
  useGetBusinessAreas
};
