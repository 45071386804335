import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import http from '../helpers/http';
import endpoints from './endpoints';

const getEvent = eventId => http.get(`${ endpoints.Events }/${ eventId }`);
const getEventUsers = eventId => http.get(`${ endpoints.Events }/${ eventId }/users`);
const getEvents = () => http.get(`${ endpoints.Events }?order[startDate]=desc`);
const createEvent = event => http.post(endpoints.Events, event);
const updateEvent = event => http.put(`${ endpoints.Events }/${ event.id }`, event);
const patchEvent = event => http.patch(`${ endpoints.Events }/${ event.id }`, event);
const addEventUser = ({ eventId, ...data }) => http.post(`${ endpoints.Events }/${ eventId }/user`, data);
const removeUser = ({ eventId, userId }) => http.delete(`${ endpoints.Events }/${ eventId }/user/${ userId }`);

const useGetEvent = eventId => useQuery(['events', { id: eventId }], () => getEvent(eventId), { enabled: eventId !== null });
const useGetEventUsers = eventId => useQuery(['eventsUsers', { eventId: eventId }], () => getEventUsers(eventId), { enabled: eventId !== null, select: x => x.eventUsers });
const useGetEvents = () => useQuery('events', () => getEvents(), { select: x => x['hydra:member'] });

const useCreateEvent = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(createEvent, {
    onSuccess: createdEvent => {
      toast.success('Meeting created');
      queryClient.invalidateQueries('events');
      onSuccess(createdEvent);
    },
  });
};

const useUpdateEvent = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateEvent, {
    onSuccess: () => {
      toast.success('Meeting modified');
      queryClient.invalidateQueries('events');
      onSuccess();
    },
  });
};

const usePatchEvent = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(patchEvent, {
    onSuccess: () => {
      toast.success('Meeting modified');
      queryClient.invalidateQueries('events');
      onSuccess();
    },
  });
};

const useAddEventUser = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(addEventUser, {
    onSuccess: () => {
      toast.success('Member added to meeting');
      queryClient.invalidateQueries('events');
      queryClient.invalidateQueries('eventsUsers');
      onSuccess();
    },
  });
};

const useRemoveEventUser = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(removeUser, {
    onSuccess: () => {
      toast.success('Member removed from meeting');
      queryClient.invalidateQueries('events');
      queryClient.invalidateQueries('eventsUsers');
      onSuccess();
    },
  });
};

export {
  useGetEvent,
  useGetEventUsers,
  useGetEvents,
  useCreateEvent,
  useUpdateEvent,
  usePatchEvent,
  useAddEventUser,
  useRemoveEventUser,
};
