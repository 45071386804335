import http from '../helpers/http';
import endpoints from './endpoints';

const createToken = async (username, password) => {
  const response = await http.post(endpoints.Token, { username, password });

  return response;
};

export {
  createToken,
};
