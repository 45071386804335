function getOrdinal (n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const dateHelper = {
  formatEnglishDateWithOrdinal: (date) => {
    // eslint-disable-next-line no-self-compare
    if (date.getTime() !== date.getTime()) {
      return;
    }
    const month = date.toLocaleDateString('en-GB', { month: 'long' });

    const day = getOrdinal(date.getDate());

    return `${ month } ${ day }, ${ date.getFullYear() }`;
  }
};

export default dateHelper;
