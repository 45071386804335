import { ErrorMessage } from 'formik';
import React from 'react';
import { Form, FormFile } from 'react-bootstrap';

import ErrorField from '../ErrorField/ErrorField';
import RoundedImage from '../RoundedImage/RoundedImage';

const imageModified = (event, onImageLoaded) => {
  // Check file type
  const validImageTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
  ];

  if (validImageTypes.indexOf(event.currentTarget.files[0].type) === -1) {
    event.currentTarget.value = '';
    return;
  }

  const reader = new FileReader();
  reader.onloadend = () => {
    onImageLoaded(reader.result);
  };
  reader.readAsDataURL(event.currentTarget.files[0]);
};

const ImageField = ({
  field,
  title,
  image,
  onImageLoaded = () => {},
}) => (
  <Form.Group className="text-center">
    <RoundedImage alt={ title } src={ image || '/assets/images/empty.png' } width={ '200px' } height={ '200px' } />
    <FormFile.Input accept=".gif,.jpg,.jpeg,.png" onChange={ event => imageModified(event, onImageLoaded) }/>
    <ErrorMessage name={ field.name } component={ ErrorField } />
  </Form.Group>
);

export default ImageField;
