import { React, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import GuardedRoute from './hoc/GuardedRoute/GuardedRoute';
import MainLayout from './hoc/MainLayout/MainLayout';
import * as actions from './store/actions';
import Home from './components/Home/Home';
import Logout from './containers/Logout/Logout';
import TheyTrustUs from './components/TheyTrustUs/TheyTrustUs';
import { Members, MemberAdd, MemberEdit, MemberDetails, MyProfile } from './components/Members';
import { Meetings, MeetingAdd, MeetingEdit, MeetingDetails } from './components/Meetings';
import Feedbacks from './components/Feedbacks/Feedbacks';
import PasswordLost from './components/ResetPassword/PasswordLost';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ContactUs from './components/ContactUs/ContactUs';
import roles from './constants/roles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0
    },
  },
});

const App = props => {
  const { onTryAuthSignup } = props;

  useEffect(() => {
    onTryAuthSignup();
  }, [onTryAuthSignup]);

  return (
    <QueryClientProvider client={ queryClient }>
      <BrowserRouter>
        <MainLayout>
          <Switch>
            <Route path="/" exact component={ Home } />
            <Route path="/logout" exact component={ Logout } />
            <Route path="/feedbacks" exact component={ Feedbacks } />
            <Route path="/they-trust-us" exact component={ TheyTrustUs } />
            <Route path="/password-lost" exact component={ PasswordLost } />
            <Route path="/reset-password/:tokenId" exact component={ ResetPassword } />
            <Route path="/contact-us" exact component={ ContactUs } />
            <Route path="/contact-us/meeting/:meetingId" exact component={ ContactUs } />
            <GuardedRoute path="/members" roles={ [roles.admin] } exact component={ Members } />
            <GuardedRoute path="/members/add" roles={ [roles.admin] } exact component={ MemberAdd } />
            <GuardedRoute path="/members/me" exact component={ MyProfile } />
            <GuardedRoute path="/members/:userId" exact component={ ({ match: { params: { userId } } }) => <MemberDetails userId={ userId } /> } />
            <GuardedRoute path="/members/:userId/edit" exact component={ MemberEdit } />

            <Route path="/meetings" exact component={ Meetings } />
            <GuardedRoute path="/meetings/add" roles={ [roles.admin] } exact component={ MeetingAdd } />
            <GuardedRoute path="/meetings/:meetingId" exact component={ ({ match: { params: { meetingId } } }) => <MeetingDetails meetingId={ meetingId } /> } />
            <GuardedRoute path="/meetings/:meetingId/edit" roles={ [roles.admin] } exact component={ MeetingEdit } />
          </Switch>
        </MainLayout>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAuthSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(null, mapDispatchToProps)(App);
