import axios from 'axios';
import { toast } from 'react-toastify';

import store from '../store';
import * as actions from '../store/actions';
import { LOCALSTORAGE_KEY } from '../store/actions/auth';

const { dispatch } = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(config => {
  if (config.method === 'post' && config.url === '/token') {
    return config;
  }

  if (config.method === 'patch') {
    config.headers['Content-Type'] = 'application/merge-patch+json';
  }

  const auth = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));

  if (auth) {
    // eslint-disable-next-line dot-notation
    config.headers['Authorization'] = `Bearer ${ auth.token }`;
  }

  return config;
}, error => {
  return Promise.reject(error);
});

instance.interceptors.response.use(config => config.data, error => {
  if (error?.response?.status === 401) {
    dispatch(actions.logout());
    if (error?.response?.data?.message === 'Expired JWT Token') {
      toast.warning('Your session has expired, please log in');
      dispatch(actions.authSetRedirectPath('/'));
    }
  } else if (error?.response?.status === 403) {
    toast.error('Access denied');
  } else if (error?.response?.status === 404) {
    toast.warning('The page you are trying to view does not exist or is not accessible to you');
  } else {
    toast.error(error?.response?.data?.detail || 'A technical error occurred');
  }

  return Promise.reject(error);
});

export default instance;
