import { ErrorMessage } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import ErrorField from '../ErrorField/ErrorField';

const StyleCheckbox = styled(Form.Check)`
  ${ props => props.top ? `top: ${ props.top }px` : '' }
`;

const CheckBoxField = ({
  field,
  ...props
}) => (
  <>
    <StyleCheckbox
        type={ props.type || 'checkbox' }
        id={ field.name + props.value }
        label={ props.label }
        value={ props.value }
        name={ field.name }
        checked={ field.value }
        { ...field }
        { ...props } />
    <ErrorMessage name={ field.name } component={ ErrorField } />
  </>
);

export default CheckBoxField;
