import React from 'react';
import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';

import ErrorField from '../ErrorField/ErrorField';

const TextField = ({
  field,
  ...props
}) => (
    <Form.Group>
      { props.label && <Form.Label>{ props.label }</Form.Label> }
      <Form.Control
        type={ props.type || 'text' }
        placeholder={ props.placeholder || props.label }
        { ...props }
        { ...field } />
      <ErrorMessage name={ field.name } component={ ErrorField } />
    </Form.Group>
);

export default TextField;
