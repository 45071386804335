import React, { useState } from 'react';
import { RiSearchLine, RiUserAddLine, RiUserFollowFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { useAddEventUser, useGetUsers } from '../../../../http';
import CustomTable from '../../../UI/CustomTable/CustomTable';
import ValidationModal from '../../../UI/ValidationModal/ValidationModale';

const getActionsAccessorOtherUsers = (meetingId, setAddUserMutationData) => function actionsAccessorOtherUsers (row) {
  return (
    <div className="table-actions">
      <RiUserAddLine
        title="Add but don't notify the member"
        onClick={ () => setAddUserMutationData({
          eventId: meetingId,
          user: row['@id'],
          notify: 0
        }) } />

      <RiUserFollowFill
        title="Add and notify the member"
        onClick={ () => setAddUserMutationData({
          eventId: meetingId,
          user: row['@id'],
          notify: 1
        }) } />
      <Link to={ `/members/${ row.id }` }>
        <RiSearchLine title="Go to member profile" />
      </Link>
    </div>
  );
};

const MeetingParticipantsAdd = ({
  meetingId,
  participants = [],
}) => {
  const { isLoading, data: users = [] } = useGetUsers();
  const addUserToMeetingMutation = useAddEventUser();
  const [addUserMutationData, setAddUserMutationData] = useState(null);

  const participantsIds = participants.map(p => p.id);

  return (
    <>
      <h4 className="text-uppercase">Members</h4>
      <CustomTable
        columns={ [
          { id: 'username', Header: 'File', accessor: 'username' },
          { id: 'company', Header: 'Company', accessor: 'company', filter: 'includes' },
          { id: 'type', Header: 'Type', accessor: row => row.companyTypes?.map(x => x.name).join(', ') },
          { id: 'contact', Header: 'Contact', accessor: row => `${ row.contact?.lastName || '' } ${ row.contact?.firstName || '' }${ row.contact?.email ? ` (${ row.contact.email })` : null }` },
          { id: 'actions', Header: 'Actions', accessor: getActionsAccessorOtherUsers(meetingId, setAddUserMutationData), disableGlobalFilter: true, disableSortBy: true }
        ] }
        data={ (users['hydra:member'] || []).filter(u => !participantsIds.includes(u.id)) }
        isLoading={ isLoading }
        searchPlaceholder="Search a company, contact, etc..."
        sortBy= { { id: 'company', desc: false } } />
      { addUserMutationData &&
        <ValidationModal
          title="Add user to meeting"
          message="Are you sure you want to add this user to this meeting ?"
          hide={ () => setAddUserMutationData(null) }
          confirmMutation={ addUserToMeetingMutation }
          confirmMutationValues={ addUserMutationData } />
      }
    </>
  );
};

export default MeetingParticipantsAdd;
