import React from 'react';

import { MeetingForm } from '..';
import { useGetEvent, useUpdateEvent } from '../../../http';
import Loader from '../../UI/Loader/Loader';

const MeetingEdit = ({ match: { params: { meetingId } } }) => {
  const mutation = useUpdateEvent();

  const { isLoading, data } = useGetEvent(meetingId);

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return <MeetingForm
    mutation={ mutation }
    data={ data }
    title="Edit meeting"
    displayWarning={ true } />;
};

export default MeetingEdit;
