import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

import LoginForm from '../../../components/LoginForm/LoginForm';
import roles from '../../../constants/roles';
import { colors, breakpoints } from '../../../constants/css';

const WhiteNavLink = styled(Nav.Link)`
  color: white !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  text-transform: uppercase;
`;

const Logo = styled(Image)`
  width: 60px;
  height: 60px;
  display: inline-block;
  vertical-align: top;
  margin-right: .5rem;
`;

const NavbarWithBackground = styled(Navbar)`
  ${ props => props.showwave
    ? `@media(max-width: ${ breakpoints.lg }) {
        background-color: ${ colors.blue };
        border-radius: 0px 0px 50px 50px;
      }`
    : `background-color: ${ colors.blue };
      border-radius: 0px 0px 50px 50px;`
  }
`;

const LogoText = styled.div`
  display: inline-block;

  span {
    color: ${ props => props.showwave ? colors.blue : colors.white } !important;
  }

  ${ props => props.showwave &&
    `@media(max-width: ${ breakpoints.lg }) {
      span { color: ${ colors.white } !important; }
    }`
  }
`;

const activeStyle = {
  borderRadius: '20px / 50%',
  backgroundColor: colors.fushia,
};

const Header = props => {
  const navigation = [
      <LinkContainer activeStyle={ activeStyle } exact={ true } key="/meetings" to="/meetings"><WhiteNavLink>Meetings</WhiteNavLink></LinkContainer>,
      <LinkContainer activeStyle={ activeStyle } exact={ true } key="/feedbacks" to="/feedbacks"><WhiteNavLink>Feedbacks</WhiteNavLink></LinkContainer>,
      <LinkContainer activeStyle={ activeStyle } exact={ true } key="/they-trust-us" to="/they-trust-us"><WhiteNavLink>They trust us</WhiteNavLink></LinkContainer>,
      <LinkContainer activeStyle={ activeStyle } exact={ true } key="/contact-us" to="/contact-us"><WhiteNavLink>Contact us</WhiteNavLink></LinkContainer>,
  ];

  if (props.roles.indexOf(roles.admin) > -1) {
    navigation.push(<LinkContainer activeStyle={ activeStyle } exact={ true } key="/members" to="/members"><WhiteNavLink>Members</WhiteNavLink></LinkContainer>);
  }

  return (
    <NavbarWithBackground expand="lg" showwave={ props.showwave || undefined }>
      <LinkContainer to="/">
        <Navbar.Brand className="ml-2">
          <Logo src="/assets/images/logo_polaris.png" alt="Logo" />
          <LogoText showwave={ props.showwave }>Polaris<br />M<span>ee</span>tings</LogoText>
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="main-navbar-nav" />
      <Navbar.Collapse id="main-navbar-nav">
        <Nav className="ml-lg-auto align-items-center">
          { navigation }
          { !props.isAuthenticated
            ? <LoginForm/>
            : (
              <>
                <Navbar.Text>
                  <LinkContainer activeStyle={ activeStyle } exact={ true } to="/members/me">
                    <WhiteNavLink>My profile</WhiteNavLink>
                  </LinkContainer>
                </Navbar.Text>
                <Navbar.Text>
                  <LinkContainer to="/logout">
                    <WhiteNavLink>Logout</WhiteNavLink>
                  </LinkContainer>
                </Navbar.Text>
              </>
              )
          }
        </Nav>
      </Navbar.Collapse>
    </NavbarWithBackground>
  );
};

const mapStateToProp = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    username: state.auth.username,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProp)(Header);
