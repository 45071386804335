import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import styled from 'styled-components';

import * as actions from '../../store/actions';
import Loader from '../UI/Loader/Loader';
import { colors } from '../../constants/css';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useQueryClient } from 'react-query';

const PasswordLostNavLink = styled(Nav.Link)`
  color: white !important;
  font-size: .8em;
  align-self: start;
`;

const ErrorIcon = styled(RiErrorWarningFill)`
  color: white !important;
  margin-right: .5rem;
`;

const FushiaButton = styled(Button)`
  background-color: ${ colors.fushia };
  border: none;

  :hover {
    background-color: ${ colors.fushia };
  }
`;

const LoginForm = props => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async values => {
      try {
        setError('');
        setIsFetching(true);
        await props.onAuth(values.username, values.password);
        queryClient.invalidateQueries();
      } catch (e) {
        setError(e.message);
      } finally {
        setIsFetching(false);
      }
    },
  });

  return (
    <Form inline onSubmit={ formik.handleSubmit }>
      <FormControl
        id="username"
        type="text"
        className="mr-sm-2"
        style={ { width: '180px' } }
        placeholder="Username"
        disabled={ isFetching }
        onChange={ formik.handleChange }
        value={ formik.values.username }
        isInvalid={ error }
      />
      <FormControl
        id="password"
        type="password"
        className="mr-sm-2"
        style={ { width: '180px' } }
        placeholder="Password"
        disabled={ isFetching }
        onChange={ formik.handleChange }
        value={ formik.values.password }
        isInvalid={ error }
      />
      { error && <ErrorIcon title={ error } /> }
      { !isFetching
        ? <FushiaButton type="submit">OK</FushiaButton>
        : <Loader size="sm" />
      }
      <LinkContainer className="small py-0 pr-0" to="/password-lost">
        <PasswordLostNavLink className="ml-2 p-0">Password lost ?</PasswordLostNavLink>
      </LinkContainer>
    </Form>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
