import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = props => (
  <>
    <Spinner
      as={ props.as || 'span' }
      animation="border"
      size={ props.size || 'lg' }
      role="status"
      aria-hidden="true" />
    <span className="sr-only">Loading...</span>
  </>
);

export default Loader;
