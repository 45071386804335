/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, breakpoints } from '../../constants/css';
import RoundedImage from '../UI/RoundedImage/RoundedImage';
import news from './News';

const FushiaButton = styled.button`
  padding: .5rem 2rem;
  color: white;
  background-color: ${ colors.fushia };
  border-radius: 15% / 50%;
  border: none;
  text-transform: uppercase;

  :hover {
    background-color: ${ colors.fushia }
  }
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`;

const ImproveYourBusinessRow = styled(Row)`
  background: url('/assets/images/Waves/from-left.svg') no-repeat;
  background-position-x: -300px;
  height: 1050px;
  padding-bottom: 5rem;

  @media(max-width: ${ breakpoints.lg }) {
    background: none;
    height: auto;
  }
`;

const ImproveYourBusinessWomanImage = styled(StyledImage)`
  margin-left: 5rem;

  @media (max-width: ${ breakpoints.lg }) {
    margin-left: 2rem;
    max-width: 160px;
  }
`;

const ImproveYourBusinessWorldImage = styled(StyledImage)`
  margin-bottom: 280px;

  @media (max-width: ${ breakpoints.lg }) {
    margin-bottom: 150px;
    max-width: 300px;
  }
`;

const DashedSeparation = styled.div`
  height: 200px;

  div.dashed {
    height: 150px;
    width: 3px;
    margin-left: 5px;
    border-left: 3px dashed #6d5e8c;
  }

  div.circle {
    background-color: ${ colors.fushia };
    border-radius: 50%;
    height: 12px;
    width: 12px;
    margin-top: 5px;
  }

  @media(max-width: ${ breakpoints.lg }) {
    height: 125px;

    * {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    div.dashed {
      height: 75px;
    }
  }
`;

const TextH2 = styled.h2`
  font-size: 4.5rem;
  font-weight: lighter;

  strong {
    font-weight: bold;
  }

  + p {
    letter-spacing: 0.07rem;
    font-weight: lighter;
  }

  @media(max-width: ${ breakpoints.lg }) {
    font-size: 3rem;
  }
`;

const FrostedDiv = styled.div`
  margin-top: 2rem;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 15px 30px;
  font-size: 1.3rem;

  a {
    color: black;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    &-left {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &-right {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .arrow[data-disabled="true"] {
    background-color: ${ colors.gray }25;
  }

  .arrow[data-disabled="false"]:hover {
    background-color: ${ colors.gray }15;
    color: ${ colors.fushia };
  }
`;

const NewsSlider = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  font-size: 1rem;

  > div {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    transition-duration: 0.5s;
  }
`;

const Home = () => {
  const [intervalId, setIntervalId] = useState(undefined);
  const [newsIndex, setNewsIndex] = useState(0);
  const maxNewsIndex = 3;

  const setNewsInterval = () => setInterval(() => {
    setNewsIndex(newsIndex => (newsIndex + 1) % (maxNewsIndex + 1));
  }, 5000);

  const clearNewsInterval = intervalId => clearInterval(intervalId);

  useEffect(() => {
    setIntervalId(setNewsInterval());
    return () => clearNewsInterval(intervalId);
  }, []);

  return (
    <Container fluid>
      <Row className="mt-10 pt-5">
        <Col lg="4" md="12" className="offset-lg-1">
          <TextH2>
            Building y<span className="text-secondary">o</span>ur <strong>Relationship</strong>
          </TextH2>
          <p>
          The specialist in international face to face meetings between selected suppliers and buyers. Mainly focused on frozen fruits and vegetables.
  We are considering adding more product categories soon.
          </p>
          <Link to="/meetings"><FushiaButton>Get started</FushiaButton></Link>
          <FrostedDiv>
            <Row>
              <Col>
                <NewsSlider onMouseEnter={ () => clearNewsInterval(intervalId) } onMouseLeave={ () => setIntervalId(setNewsInterval()) }>
                  { news.slice(0, maxNewsIndex + 1).map((x, i) => <div key={ i } style={ i === 0 ? { marginLeft: `-${ newsIndex * 100 }%` } : {} }>
                    <RoundedImage
                      className="float-right"
                      src={ x.image } />

                    <a className="font-weight-bold mb-3" href={ x.link }>{ x.title }</a>

                    <div className="clearfix" dangerouslySetInnerHTML={ { __html: x.description } }></div>

                    { x.additionalImages &&
                      <div className="d-flex flex-wrap">
                        { x.additionalImages.map((i, index) => <RoundedImage key={ index } src={ i } width="140px" height="140px" />) }
                      </div>
                    }
                  </div>) }
                </NewsSlider>
              </Col>
            </Row>
          </FrostedDiv>
        </Col>
        <Col md="12" lg="6" className="text-right">
          <StyledImage src="/assets/images/Home/persons-shaking-hands.svg" />
        </Col>
      </Row>
      <ImproveYourBusinessRow>
        <Col md="12" className="mt-5 d-flex justify-content-center">
          <iframe width="300" height="169" src="https://www.youtube.com/embed/91sK0dYnHTY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowFullScreen" className="mr-1"></iframe>
          <iframe width="300" height="169" src="https://www.youtube.com/embed/EdpnI3XM7VA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowFullScreen" className="ml-1"></iframe>
        </Col>
        <Col md="12" className="mt-3 d-flex justify-content-center">
          <iframe width="300" height="169" src="https://www.youtube.com/embed/ntRM_Oy32MI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowFullScreen" className="mr-1"></iframe>
          <iframe width="300" height="169" src="https://www.youtube.com/embed/bWuh0VygSpw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowFullScreen" className="ml-1"></iframe>
        </Col>
        <Col md="7" className="d-flex align-items-end align-self-end order-2">
          <ImproveYourBusinessWomanImage src="/assets/images/Home/woman-looking-right.svg" />
          <ImproveYourBusinessWorldImage src="/assets/images/Home/world.svg" />
        </Col>
        <Col md="12" lg="4" className="align-self-center order-1 order-lg-3">
          <DashedSeparation>
            <div className="dashed"></div>
            <div className="circle"></div>
          </DashedSeparation>
          <TextH2 className="text-center text-lg-left">
            Impr<span className="text-secondary">o</span>ve your business with <strong>Polaris Meetings</strong>
          </TextH2>
          <p>
            Our mission: Offer you the opportunity to meet the most important buyers in your product category in one place.
          </p>
          <Row>
            <Link to="/meetings"><FushiaButton className="ml-3">Participate</FushiaButton></Link>
          </Row>
        </Col>
      </ImproveYourBusinessRow>
    </Container>
  );
};

export default Home;
