import { useQuery } from 'react-query';

import http from '../helpers/http';
import endpoints from './endpoints';

const getCompanyTypes = () => http.get(`${ endpoints.CompanyTypes }?pagination=false`);

const useGetCompanyTypes = () => useQuery('company_types', () => getCompanyTypes(), { select: x => x['hydra:member'] });

export {
  useGetCompanyTypes,
};
