import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import http from '../helpers/http';
import endpoints from './endpoints';

const getResetPasswordValidity = tokenId => http.get(`${ endpoints.ResetPasswordTokens }/${ tokenId }`);

const resetPasswordToken = userId => http.post(endpoints.ResetPasswordTokens, { user: userId });

const resetPassword = ({ tokenId, values }) => http.post(`${ endpoints.ResetPasswordTokens }/${ tokenId }`, { password: values.password });

const useGetResetPasswordValidity = tokenId => useQuery(['reset_password_tokens', { id: tokenId }], () => getResetPasswordValidity(tokenId), { select: x => x?.validity });

const useResetPasswordToken = () => useMutation(resetPasswordToken, {
  onSuccess: () => toast.success('An email with a link to reset your password has been sent to your email address')
});

const useResetPassword = (onSuccess = () => {}) => useMutation(resetPassword, {
  onSuccess: () => {
    toast.success('Password modified');
    onSuccess();
  }
});

export {
  useGetResetPasswordValidity,
  useResetPasswordToken,
  useResetPassword,
};
