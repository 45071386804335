import React from 'react';
import { useCreateUser } from '../../../http/users';

import MemberForm from '../MemberForm/MemberForm';

const MemberAdd = ({ history }) => {
  const mutation = useCreateUser(() => {
    history.push('/members');
  });

  return <MemberForm mutation={ mutation } title="Add member" />;
};

export default MemberAdd;
