import React from 'react';
import { ErrorMessage, getIn } from 'formik';
import { Form } from 'react-bootstrap';

import ErrorField from '../ErrorField/ErrorField';

const TextAreaField = ({
  field,
  form: { touched, errors },
  ...props
}) => (
    <Form.Group>
      { props.label && <Form.Label>{ props.label }</Form.Label> }
      <Form.Control
        as="textarea"
        placeholder={ props.placeholder || props.label }
        isInvalid={ getIn(errors, field.name) && getIn(touched, field.name) }
        { ...props }
        { ...field } />
      <ErrorMessage name={ field.name } component={ ErrorField } />
    </Form.Group>
);

export default TextAreaField;
