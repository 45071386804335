import { React } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Form, Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import ValidationMessages from '../../../messages/ValidationMessages';
import TextAreaField from '../../UI/TextAreaField/TextAreaField';
import Loader from '../../UI/Loader/Loader';
import { useGetEvents } from '../../../http';
import SelectField from '../../UI/SelectField/SelectField';

const FeedbackForm = ({
  data = {},
  mutation = () => {},
  isShown = false,
  canSelectEvent = false,
  isEditing = false,
  hide = () => {},
}) => {
  const { isLoading, data: meetings = [] } = useGetEvents();

  const validationSchema = Yup.object().shape({
    content: Yup.string().required(ValidationMessages.required).min(20, ValidationMessages.string.length.min20),
    event: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      id: data.id || null,
      content: data.content || '',
      createdAt: data.createdAt || new Date(),
      event: data.event || ''
    },
    validationSchema: validationSchema,
    onSubmit: values => { values.event = values.event || undefined; return mutation.mutateAsync(values); },
  });

  return (
    <Modal show={ isShown } onHide={ hide }>
      <FormikProvider value={ formik }>
        <Form onSubmit={ formik.handleSubmit }>
          <fieldset disabled={ formik.isSubmitting }>
            <Modal.Header closeButton>
              <Modal.Title>Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            { isLoading
              ? <div className="text-center"><Loader /></div>
              : <>
                { canSelectEvent &&
                  <Field
                    placeholder="Meeting"
                    name="event"
                    values={ meetings }
                    selectKey={ m => m['@id'] }
                    selectLabel={ m => m.title }
                    nullable={ !isEditing || !data.event }
                    isInvalid={ formik.touched.event && !!formik.errors.event }
                    component={ SelectField } />
                }
                <Field
                  placeholder="Feedback"
                  name="content"
                  isInvalid={ formik.touched.content && !!formik.errors.content }
                  rows={ 4 }
                  component={ TextAreaField } />
              </>
            }
            </Modal.Body>
            <Modal.Footer>
              <Button type="reset" onClick={ formik.resetForm } className="btn btn-small btn-danger ml-auto mr-2">Reset</Button>
              <Button type="submit" className="btn btn-small btn-primary mr-auto ml-2">
                {
                  formik.isSubmitting
                    ? <Loader size="sm" />
                    : 'Save'
                }
              </Button>
            </Modal.Footer>
          </fieldset>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default FeedbackForm;
