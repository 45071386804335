import React from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Col, Form, Row, Button } from 'react-bootstrap';
import styled from 'styled-components';

import Loader from '../../../UI/Loader/Loader';
import GuardedElement from '../../../../hoc/GuardedElement/GuardedElement';
import roles from '../../../../constants/roles';
import ImageField from '../../../UI/ImageField/ImageField';
import RoundedImage from '../../../UI/RoundedImage/RoundedImage';
import { colors } from '../../../../constants/css';
import { RiGlobalLine, RiMailFill, RiPencilLine, RiPhoneFill } from 'react-icons/ri';

const DivCompany = styled.div`
  text-transform: uppercase;
  color: ${ colors.fushia }
`;

const onImageLoaded = (formik, readerResult) => {
  formik.setFieldValue('avatar', readerResult);
  formik.submitForm();
};

const MemberDetailsProfile = ({
  data,
  userAbsoluteId,
  title = 'Member profile',
  canChangePicture = false,
  changePictureMutation = () => {},
  canResetPassword = false,
  resetPasswordMutation = () => {},
  canEdit = false,
}) => {
  const formResetPassword = useFormik({
    initialValues: { },
    onSubmit: () => resetPasswordMutation.mutateAsync(userAbsoluteId)
  });

  const formImage = useFormik({
    initialValues: {
      id: data.id || '',
      avatar: data.avatar || null,
    },
    onSubmit: changePictureMutation.mutateAsync
  });

  return (
    <fieldset className="border p-2">
      <legend className="w-auto">
        { title }
        { canEdit
          ? <Link to={ `/members/${ data.id }/edit` } className="ml-2">
              <RiPencilLine title="Edit" />
            </Link>
          : <GuardedElement roles={ [roles.admin] }>
              <Link to={ `/members/${ data.id }/edit` } className="ml-2">
                <RiPencilLine title="Edit" />
              </Link>
            </GuardedElement>
        }
        { canResetPassword &&
          <FormikProvider value={ formResetPassword }>
            <Form onSubmit={ formResetPassword.handleSubmit } className="d-inline-block ml-2">
              { formResetPassword.isSubmitting
                ? <Loader />
                : <Button
                    size="sm"
                    variant="link"
                    type="submit">
                    Reset password
                  </Button>
              }
            </Form>
          </FormikProvider>
        }
      </legend>
      <Row>
        <Col sm="4">
          <div className="text-center mb-2">
            { canChangePicture
              ? <FormikProvider value={ formImage }>
                  <Form onSubmit={ formImage.handleSubmit }>
                    { formImage.isSubmitting
                      ? <div className="text-center"><Loader /></div>
                      : <Field
                          title={ data.company }
                          name="avatar"
                          image={ formImage.values.avatar }
                          onImageLoaded={ readerResult => onImageLoaded(formImage, readerResult) }
                          component={ ImageField } />
                    }
                  </Form>
                </FormikProvider>
              : <RoundedImage src={ data.avatar } width={ '200px' } height={ '200px' } />
            }
          </div>
          <div><span className="font-weight-bold mr-2">Certifications:</span>{ data.certification }</div>
          <div><span className="font-weight-bold mr-2">Company size:</span>{ data.employeeAmount }</div>
        </Col>
        <Col sm="8">
          <DivCompany>
            <div className="bordered d-inline-block">Company</div> { data.company }
            { data.website &&
              <a className="ml-2" href={ (data.website.indexOf('http') === 0 ? data.website : `http://${ data.website }`) }>
                <RiGlobalLine />
              </a>
            }
          </DivCompany>
          <div>
            <span className="font-weight-bold mr-2">File number:</span>
            { data.fileNumber }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Address:</span>
            <span>{ data.headQuarter?.address } { data.headQuarter?.zipCode } { data.headQuarter?.city } { data.headQuarter?.country }</span>
          </div>
          <div>
            <span className="font-weight-bold mr-2">Contact name:</span>
            <span>
              { data.contact?.lastName } { data.contact?.firstName }
            </span>
            { data.contact?.email &&
              <div className="d-inline-block ml-2">
                <a href={ `mailto:${ data.contact?.email }` }>
                  <RiMailFill className="mr-2" />
                  { data.contact?.email }
                </a>
              </div>
            }
            { data.contact?.phoneNumber &&
              <div className="d-inline-block ml-2">
                <a href={ `tel:${ data.contact?.phoneNumber }` }>
                  <RiPhoneFill className="mr-2" />
                  { data.contact?.phoneNumber }
                </a>
              </div>
            }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Position:</span>
            { data.position }
          </div>
          <div>
            { data.companyTypes && data.companyTypes.map(t => `I am a ${ t.name.toLowerCase() }`).join(', ') }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Activities:</span>
            { data.activities?.map(t => t.name).join(', ') || 'No data' }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Business areas:</span>
            { data.businessAreas?.map(t => t.name).join(', ') || 'No data' }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Business partners:</span>
            { data.businessPartners?.map(t => t.name).join(', ') || 'No data' }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Types of products:</span>
            { data.productRange?.map(t => t.name).join(', ') || 'No data' }
          </div>
          <div>
            <span className="font-weight-bold mr-2">Brands:</span>
            { data.brands }
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="2">
          <span className="font-weight-bold mr-2">Company profile:</span>
        </Col>
        <Col sm="10">
          <p className="text-justify">{ data.companyInfo }</p>
        </Col>
      </Row>
      <GuardedElement roles={ [roles.admin] }>
        <Row>
          <Col sm="2">
            <span className="font-weight-bold mr-2">Remarks:</span>
          </Col>
          <Col sm="10">
            <p className="text-justify">{ data.remarks }</p>
          </Col>
        </Row>
      </GuardedElement>
    </fieldset>
  );
};

export default MemberDetailsProfile;
