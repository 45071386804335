import { React } from 'react';

import { img } from './TheyTrustUs.module.scss';

const images = [
  'Blatand.jpg',
  'Bleuets-Mistassini.jpg',
  'Brecon.png',
  'Iberfruta.png',
  'ITN-group.png',
  'Polarica.png',
  'Puratos.png',
];

const TheyTrustUs = () => {
  return (
    <>
      <h1 className="text-uppercase"><div className="bordered d-inline-block">Improve</div> your business with <span className="text-secondary">Polaris</span> events</h1>
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
        { images.map((x, i) => <div key={ i } className={ img } style={ { backgroundImage: `url('/assets/images/they-trust-us/${ x }')` } } />) }
      </div>
    </>
  );
};

export default TheyTrustUs;
