import { Field, FormikProvider, useFormik } from 'formik';
import { connect } from 'react-redux';
import { Row, Button, Col } from 'react-bootstrap';
import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';

import { useCreateContactUs } from '../../http';
import ValidationMessages from '../../messages/ValidationMessages';
import Loader from '../UI/Loader/Loader';
import TextField from '../UI/TextField/TextField';
import SelectField from '../UI/SelectField/SelectField';
import TextAreaField from '../UI/TextAreaField/TextAreaField';
import { colors } from '../../constants/css';
import { RiMailFill, RiPhoneFill } from 'react-icons/ri';

const RoundedRow = styled(Row)`
  background-color: white;
  border-radius: 30px;
`;

const RoundedCol = styled(Col)`
  background-color: ${ colors.blue };
  border-radius: 30px;
  color: ${ colors.white };
  position: relative;
`;

const CirclesDiv = styled.div`
  background: url('/assets/images/Contact-Us/Circles.png') no-repeat;
  background-size: cover;
  width: 50%;
  height: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 30px;
`;

const TransparentText = styled.span`
  color: ${ colors.white } !important;
  opacity: 0.75;
`;

const ContactInformationDiv = styled.div`
  font-size: 1.75rem;

  a:hover {
    text-decoration-color: ${ colors.fushia };
  }

  span {
    font-size: 1.25rem;
  }
`;

const requestChoices = [
  { id: 'Event registration request', name: 'Event registration request' },
  { id: 'Unsubscribe from an event', name: 'Unsubscribe from an event' },
  { id: 'Information request', name: 'Information request' },
  { id: 'Other', name: 'Other' },
];

const ContactUs = ({ authenticatedUser }) => {
  const formik = useFormik({
    initialValues: {
      email: authenticatedUser?.email || '',
      company: authenticatedUser?.company || '',
      content: '',
      request: requestChoices[0].id,
      event: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(ValidationMessages.string.invalidEmail).required(ValidationMessages.required),
      company: Yup.string().required(ValidationMessages.required),
      content: Yup.string().required(ValidationMessages.required).min(20, ValidationMessages.string.length.min20).max(2000, ValidationMessages.string.length.max2000),
      request: Yup.string().required(ValidationMessages.required)
    }),
    onSubmit: async values => mutation.mutateAsync(values)
  });

  const mutation = useCreateContactUs(() => {
    formik.resetForm();
  });

  return (
    <>
      <h1 className="text-center font-weight-bold mt-5">Contact Us</h1>
      <h2 className="text-center text-gray text-12 mb-5">Any question or remark ? Would you like to join us ?</h2>
      <RoundedRow>
        <RoundedCol md="4" className="p-4">
          <h3 className="font-weight-bold">Contact information</h3>
          <p><TransparentText>Fill up the form and our team will get back to you.</TransparentText></p>
          <ContactInformationDiv className="mt-2">
            <Row>
              <Col xs="2">
                <RiPhoneFill className="text-secondary" />
              </Col>
              <Col xs="10">
                <a href="tel:00 33 6 22 05 33 22">
                  <TransparentText className="d-block">
                    00 33 6 22 05 33 22
                  </TransparentText>
                </a>
                <a href="tel:00 33 6 20 85 60 42">
                  <TransparentText className="d-block">
                    00 33 6 20 85 60 42
                  </TransparentText>
                </a>
              </Col>
            </Row>
          </ContactInformationDiv>

          <ContactInformationDiv>
            <Row>
              <Col xs="2">
                <RiMailFill className="text-secondary" />
              </Col>
              <Col xs="10">
                <a href="mailto:contact@polarismeetings.com">
                  <TransparentText>
                    contact@polarismeetings.com
                  </TransparentText>
                </a>
              </Col>
            </Row>
          </ContactInformationDiv>
          <CirclesDiv />
        </RoundedCol>
        <Col md="8">
          <FormikProvider value={ formik }>
            <form onSubmit={ formik.handleSubmit } className="p-3">
              <fieldset disabled={ formik.isSubmitting }>
                <Row>
                  <Col>
                    <Field
                      label="Email"
                      name="email"
                      disabled={ authenticatedUser?.email }
                      isInvalid={ formik.touched.email && !!formik.errors.email }
                      component={ TextField } />
                  </Col>

                  <Col>
                    <Field
                      label="Company"
                      name="company"
                      disabled={ authenticatedUser?.company }
                      isInvalid={ formik.touched.company && !!formik.errors.company }
                      component={ TextField } />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Field
                      custom
                      label="Request"
                      name="request"
                      values={ requestChoices }
                      isInvalid={ formik.touched.request && !!formik.errors.request }
                      component={ SelectField } />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Field
                      label="Message"
                      name="content"
                      rows={ 5 }
                      isInvalid={ formik.touched.content && !!formik.errors.content }
                      component={ TextAreaField } />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="d-flex justify-content-between">
                    <Button type="reset" onClick={ formik.resetForm } className="btn btn-small btn-primary btn-text-light btn-rounded-10 py-2 px-3">Reset Message</Button>
                    <Button type="submit" className="btn btn-small btn-secondary btn-text-light btn-rounded-10 py-2 px-3">
                      {
                        formik.isSubmitting
                          ? <Loader size="sm" />
                          : 'Send Message'
                      }
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </form>
          </FormikProvider>
        </Col>
      </RoundedRow>
    </>
  );
};

const mapStateToProps = state => {
  return {
    authenticatedUser: state.auth,
  };
};

export default connect(mapStateToProps)(ContactUs);
