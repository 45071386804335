import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

const ThumbLink = styled.span`
  display: inline-block;
  margin: .25rem;

  img {
    max-width: ${ props => props.width || '150px' };
    max-height: ${ props => props.height || '150px' };
    border-radius: 1rem;
  }
`;

const RoundedImage = ({ src, fileName, ...props }) => (
  <ThumbLink { ...props }>
    <Image src={ src } alt={ fileName } />
  </ThumbLink>
);

export default RoundedImage;
