import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import http from '../helpers/http';
import endpoints from './endpoints';

const getMedia = mediaId => http.get(`${ endpoints.MediaObjects }/${ mediaId }`);
const getMediaContent = contentUrl => http.get(contentUrl, { responseType: 'blob' });

const createMedia = file => {
  const body = new FormData();
  body.append('file', file);

  return http({
    url: endpoints.MediaObjects,
    method: 'post',
    data: body,
    headers: { 'Content-type': 'multipart/form-data' },
  });
};

const deleteMedia = media => http.delete(`${ endpoints.MediaObjects }/${ media.id }`);

const useGetMedia = mediaId => useQuery(['media_objects', { id: mediaId }], () => getMedia(mediaId));
const useGetMediaContent = contentUrl => useQuery(['media_objects', { contentUrl }], () => getMediaContent(contentUrl), {
  select: window.URL.createObjectURL,
  enabled: contentUrl !== undefined,
});

const useCreateMedia = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(createMedia, {
    onSuccess: media => {
      toast.success('Media added');
      queryClient.invalidateQueries('media_objects');
      onSuccess(media);
    }
  });
};

const useDeleteMedia = (eventId, onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMedia, {
    onSuccess: media => {
      toast.success('Media deleted');
      queryClient.invalidateQueries(['events', { id: eventId }]);
      onSuccess(media);
    }
  });
};

export {
  useGetMedia,
  useGetMediaContent,
  useCreateMedia,
  useDeleteMedia,
};
