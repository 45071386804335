import React from 'react';

import { MeetingForm } from '..';
import { useCreateEvent } from '../../../http';

const MeetingAdd = ({ history }) => {
  const mutation = useCreateEvent(createdEvent => {
    history.push(`/meetings/${ createdEvent.id }`);
  });

  return <MeetingForm mutation={ mutation } title="Add meeting" />;
};

export default MeetingAdd;
