import React from 'react';
import ModalImage from 'react-modal-image';
import styled from 'styled-components';

const ThumbLink = styled.span`
  display: inline-block;
  margin: .25rem;

  img {
    border-radius: 1rem;
    &.small-preview-modal {
      max-width: ${ props => props.width || '150px' } !important;
      max-height: ${ props => props.height || '150px' } !important;
    }
  }
`;

const ModalRoundedImage = ({ src, fileName, ...props }) => (
  <ThumbLink { ...props }>
    <ModalImage className='small-preview-modal' small={ src } large={ src } alt={ fileName } hideZoom={ true } />
  </ThumbLink>
);

export default ModalRoundedImage;
