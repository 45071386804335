import * as actionTypes from './actionTypes';
import { createToken } from '../../http';

export const LOCALSTORAGE_KEY = 'auth';

export const authSuccess = (token, username, roles, id, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    username: username,
    roles: roles,
    id: id,
    userId: userId,
  };
};

export const logout = () => dispatch => {
  localStorage.removeItem(LOCALSTORAGE_KEY);
  dispatch(logoutSucceed());
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (email, password) => async dispatch => {
  try {
    const response = await createToken(email, password);

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({
      token: response.token,
      username: response.username,
      roles: response.roles,
      id: response.id,
      userId: response['@id'],
    }));
    dispatch(authSuccess(response.token, response.username, response.roles, response.id, response['@id']));
  } catch (error) {
    throw new Error(error?.response
      ? error.response.data.message
      : 'Authentication request could not be processed due to a system problem.'
    );
  }
};

export const authCheckState = () => dispatch => {
  const auth = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));

  if (!auth?.token) {
    return dispatch(logout());
  }

  dispatch(authSuccess(auth.token, auth.username, auth.roles, auth.id, auth.userId));
};

export const authSetRedirectPath = redirectPath => {
  return {
    type: actionTypes.AUTH_SET_REDIRECT_PATH,
    path: redirectPath,
  };
};
