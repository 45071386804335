import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import * as actions from '../../store/actions';

const GuardedRoute = props => {
  // eslint-disable-next-line no-mixed-operators
  if (!props.isAuthenticated || props.roles && !props.roles.every(x => props.userRoles.includes(x))) {
    const { pathname, search, hash } = props.location;
    props.onAuthSetRedirectPath(pathname + search + hash);

    return <Redirect to="/" />;
  }

  return <Route { ...props } />;
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userRoles: state.auth.roles,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuthSetRedirectPath: redirectPath => dispatch(actions.authSetRedirectPath(redirectPath)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardedRoute);
