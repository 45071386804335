import { React } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RiGlobalLine, RiMailFill, RiPencilLine, RiSearchLine } from 'react-icons/ri';
import styled from 'styled-components';

import { useGetUsers, getUsersExport } from '../../../http';
import CustomTable from '../../UI/CustomTable/CustomTable';

const WaveFromRight = styled.div`
  width: 200px;
  height: 500px;
  background: url('/assets/images/Members/wave-from-right.png') no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: -150px;
  z-index: -1;
`;

const WaveFromLeft = styled.div`
  width: 216px;
  height: 670px;
  background: url('/assets/images/Members/wave-from-left.png') no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: -150px;
  z-index: -1;
`;

const actionsAccessor = row => (
  <div className="table-actions">
    <Link to={ `/members/${ row.id }` }>
      <RiSearchLine title="View" />
    </Link>
    <Link to={ `/members/${ row.id }/edit` }>
      <RiPencilLine title="Edit" />
    </Link>
  { row.website &&
    <a href={ row.website } alt={ row.company } target="_blank" rel="noreferrer">
      <RiGlobalLine title={ `${ row.company } website` } />
    </a>
  }
  { row.contact?.email &&
    <a href={ `mailto:${ row.contact.email }` }>
      <RiMailFill title={ `Send email to ${ row.contact.email }` } />
    </a>
  }
  </div>);

const MembersList = () => {
  const { isLoading, data: users = [] } = useGetUsers();

  return (
    <>
      <h1 className="bordered d-inline-block font-weight-bold mb-3">Members</h1>
      <CustomTable
        columns={ [
          { id: 'username', Header: 'File', accessor: 'username' },
          { id: 'company', Header: 'Company', accessor: 'company', filter: 'includes' },
          { id: 'type', Header: 'Type', accessor: row => row.companyTypes?.map(x => x.name).join(', ') },
          { id: 'contact', Header: 'Contact', accessor: row => `${ row.contact?.lastName || '' } ${ row.contact?.firstName || '' }${ row.contact?.email ? ` (${ row.contact.email })` : null }` },
          { id: 'headquarter', Header: 'Headquarter', accessor: row => `${ row.headQuarter.city } ${ row.headQuarter.country }` },
          { id: 'position', Header: 'Position', accessor: 'position' },
          { id: 'actions', Header: 'Actions', accessor: actionsAccessor, disableGlobalFilter: true, disableSortBy: true }
        ] }
        additionalComponent={ <Link to="/members/add" className="ml-auto align-self-end"><Button className="btn-small btn-rounded-10 btn-text-light btn-secondary"> Add new member </Button></Link> }
        data={ users['hydra:member'] || [] }
        exportable
        exportFn={ getUsersExport }
        isLoading={ isLoading }
        searchPlaceholder="Search a company, contact, etc..."
        sortBy= { { id: 'company', desc: false } } />
      <WaveFromLeft />
      <WaveFromRight />
    </>
  );
};

export default MembersList;
