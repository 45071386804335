import React from 'react';
import { Link } from 'react-router-dom';

import CustomTable from '../../../UI/CustomTable/CustomTable';
import Loader from '../../../UI/Loader/Loader';
import dateHelper from '../../../../helpers/date';
import { MeetingDetails } from '../../../Meetings';
import { RiMailFill, RiSearchLine } from 'react-icons/ri';
import { useGetUserEvents } from '../../../../http';

const actionsAccessor = row => (
  <div className="table-actions">
    <Link to={ `/meetings/${ row.id }` }>
      <RiSearchLine title="View" />
    </Link>
    <Link to={ `/contact-us/meeting/${ row.id }` }>
      <RiMailFill title="Contact us about this meeting" />
    </Link>
  </div>
);

const MemberDetailsMeetings = ({ userId }) => {
  const { isLoading, data = [] } = useGetUserEvents(userId);

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return <fieldset className="border p-2">
    <legend className="w-auto">My meetings</legend>
    <CustomTable
      columns={ [
        { id: 'title', Header: 'Title', accessor: 'event.title' },
        { id: 'date', Header: 'Date', accessor: row => `${ dateHelper.formatEnglishDateWithOrdinal(new Date(row?.event?.startDate)) } to ${ dateHelper.formatEnglishDateWithOrdinal(new Date(row?.event?.endDate)) }` },
        { id: 'location', Header: 'Location', accessor: row => `${ row?.event.city } ${ row?.event.country }` },
        { id: 'booth', Header: 'Booth', accessor: 'boothNumber' },
        { id: 'actions', Header: 'Actions', accessor: actionsAccessor, disableGlobalFilter: true, disableSortBy: true }
      ] }
      data={ data }
      showSearch={ false }
      selectable
      selectRowId={ row => row.original.event.id }
      selectedComponent={ <MeetingDetails /> }
      selectedComponentProps={ rowId => ({ meetingId: rowId }) }
      pageSize={ 4 } />
  </fieldset>;
};

export default MemberDetailsMeetings;
