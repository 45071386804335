import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import http from '../helpers/http';
import endpoints from './endpoints';

const getUserEvents = userId => http.get(`${ endpoints.EventUsers }?user=${ userId }`);
const getEventUsersExport = ids => http.post(`${ endpoints.EventUsers }/export`, { eventUsers: ids });
const updateEventUser = eventUserId => data => http.patch(`${ endpoints.EventUsers }/${ eventUserId }`, data);

const useGetUserEvents = userId => useQuery(['events', { userId: userId }], () => getUserEvents(userId), { select: x => x['hydra:member'] });
const useUpdateEventUser = (eventUserId, onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateEventUser(eventUserId), {
    onSuccess: () => {
      toast.success('Booth number modified');
      queryClient.invalidateQueries('events');
      queryClient.invalidateQueries('eventsUsers');
      onSuccess();
    }
  });
};

export {
  useGetUserEvents,
  useUpdateEventUser,
  getEventUsersExport,
};
