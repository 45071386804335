import React from 'react';
import { FormikProvider, Field, useFormik } from 'formik';
import { Row, Col, Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';

import { useGetActivities, useGetBusinessAreas, useGetBusinessPartners, useGetCompanyTypes, useGetProductRange } from '../../../http';
import ValidationMessages from '../../../messages/ValidationMessages';
import TextField from '../../UI/TextField/TextField';
import CheckBoxList from '../../UI/CheckBoxList/CheckBoxList';
import Loader from '../../UI/Loader/Loader';
import ImageField from '../../UI/ImageField/ImageField';
import TextAreaField from '../../UI/TextAreaField/TextAreaField';
import GuardedElement from '../../../hoc/GuardedElement/GuardedElement';
import roles from '../../../constants/roles';
import SelectField from '../../UI/SelectField/SelectField';

const MemberForm = ({ mutation, data = {}, title, editing = false, isadmin }) => {
  const employeeAmount = [
    { id: '1 - 10', name: '1 - 10' },
    { id: '11 - 60', name: '11 - 60' },
    { id: '61 - 500', name: '61 - 500' },
    { id: '500 +', name: '500 +' },
  ];

  const { isLoading: isLoadingActivities, data: activities = [] } = useGetActivities();
  const { isLoading: isLoadingBusinessAreas, data: businessAreas = [] } = useGetBusinessAreas();
  const { isLoading: isLoadingBusinessPartners, data: businessPartners = [] } = useGetBusinessPartners();
  const { isLoading: isLoadingCompanyTypes, data: companyTypes = [] } = useGetCompanyTypes();
  const { isLoading: isLoadingProductRange, data: productRange = [] } = useGetProductRange();

  const isLoading = isLoadingActivities ||
    isLoadingBusinessAreas ||
    isLoadingBusinessPartners ||
    isLoadingCompanyTypes ||
    isLoadingProductRange;

  const initialValues = {
    id: data.id || null,
    username: data.username || '',
    fileNumber: data.fileNumber || undefined,
    position: data.position || '',
    company: data.company || '',
    companyInfo: data.companyInfo || '',
    companyTypes: data.companyTypes?.map(x => x['@id']) || [],
    remarks: data.remarks || '',
    avatar: data.avatar || null,
    certification: data.certification || '',
    employeeAmount: data.employeeAmount || '',
    website: data.website || '',
    headQuarter: {
      address: data.headQuarter?.address || '',
      zipCode: data.headQuarter?.zipCode || '',
      city: data.headQuarter?.city || '',
      country: data.headQuarter?.country || '',
    },
    contact: {
      id: data.contact?.['@id'] || null,
      lastName: data.contact?.lastName || '',
      firstName: data.contact?.firstName || '',
      email: data.contact?.email || '',
      phoneNumber: data.contact?.phoneNumber || '',
    },
    activities: data.activities?.map(x => x['@id']) || [],
    businessAreas: data.businessAreas?.map(x => x['@id']) || [],
    brands: data.brands || '',
    businessPartners: data.businessPartners?.map(x => x['@id']) || [],
    productRange: data.productRange?.map(x => x['@id']) || [],
  };

  const validationSchema = Yup.object().shape({
    position: Yup.string().max(255, ValidationMessages.string.length.max255),
    company: Yup.string().required(ValidationMessages.required).max(180, ValidationMessages.string.length.max180),
    companyInfo: Yup.string().max(2000, ValidationMessages.string.length.max2000),
    headQuarter: Yup.object().shape({
      address: Yup.string().max(255, ValidationMessages.string.length.max255),
      zipCode: Yup.string().max(255, ValidationMessages.string.length.max255),
      city: Yup.string().max(255, ValidationMessages.string.length.max255),
      country: Yup.string().max(255, ValidationMessages.string.length.max255),
    }),
    contact: Yup.object().shape({
      lastName: Yup.string().required(ValidationMessages.required).max(255, ValidationMessages.string.length.max255),
      firstName: Yup.string().required(ValidationMessages.required).max(255, ValidationMessages.string.length.max255),
      email: Yup.string().email(ValidationMessages.string.invalidEmail).required(ValidationMessages.required).max(255, ValidationMessages.string.length.max255),
      phoneNumber: Yup.string().max(255, ValidationMessages.string.length.max255),
    }),
    certification: Yup.string().max(255, ValidationMessages.string.length.max255),
    website: Yup.string().max(255, ValidationMessages.string.length.max255),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async values => { return await mutation.mutateAsync(values); },
  });

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return (
    <FormikProvider value={ formik }>
      <Form onSubmit={ formik.handleSubmit }>
        <fieldset disabled={ formik.isSubmitting }>
          <legend>{ title }</legend>
          <Row>
            <Col sm="12" md="6">
              <Field
                title={ formik.values.company }
                name="avatar"
                image={ formik.values.avatar }
                onImageLoaded={ readerResult => formik.setFieldValue('avatar', readerResult) }
                isInvalid={ formik.touched.avatar && !!formik.errors.avatar }
                component={ ImageField } />

              <Field
                label="Certifications"
                name="certification"
                isInvalid={ formik.touched.certification && !!formik.errors.certification }
                component={ TextField } />

              <Field
                custom
                label="Company size"
                name="employeeAmount"
                values={ employeeAmount }
                isInvalid={ formik.touched.employeeAmount && !!formik.errors.employeeAmount }
                component={ SelectField } />

              <Field name="activities"
                label="Activities"
                values={ activities }
                formValues={ formik.values.activities }
                setFormValues={ values => { formik.setFieldValue('activities', values); } }
                isInvalid={ formik.touched.activities && !!formik.errors.activities }
                component={ CheckBoxList } />

              <Field name="businessAreas"
                label="Business areas"
                values={ businessAreas }
                formValues={ formik.values.businessAreas }
                setFormValues={ values => { formik.setFieldValue('businessAreas', values); } }
                isInvalid={ formik.touched.businessAreas && !!formik.errors.businessAreas }
                component={ CheckBoxList } />

              <Field name="businessPartners"
                label="Business partners"
                values={ businessPartners }
                formValues={ formik.values.businessPartners }
                setFormValues={ values => { formik.setFieldValue('businessPartners', values); } }
                isInvalid={ formik.touched.businessPartners && !!formik.errors.businessPartners }
                component={ CheckBoxList } />

              <Field name="productRange"
                label="Types of products"
                values={ productRange }
                formValues={ formik.values.productRange }
                setFormValues={ values => { formik.setFieldValue('productRange', values); } }
                isInvalid={ formik.touched.productRange && !!formik.errors.productRange }
                component={ CheckBoxList } />
            </Col>
            <Col sm="12" md="6">
              { editing &&
                <Field
                  type="number"
                  label="File number"
                  name="fileNumber"
                  disabled={ true }
                  component={ TextField } />
              }

              <Field
                label="Position"
                name="position"
                isInvalid={ formik.touched.position && !!formik.errors.position }
                component={ TextField } />

              <Field
                label="Company"
                name="company"
                isInvalid={ formik.touched.company && !!formik.errors.company }
                component={ TextField } />

              <Field
                label="Company profile"
                name="companyInfo"
                isInvalid={ formik.touched.companyInfo && !!formik.errors.companyInfo }
                rows={ 10 }
                component={ TextAreaField } />

              <GuardedElement roles={ [roles.admin] }>
                <Field
                    label="Remarks"
                    name="remarks"
                    isInvalid={ formik.touched.remarks && !!formik.errors.remarks }
                    rows={ 10 }
                    component={ TextAreaField } />
              </GuardedElement>

              <Field name="companyTypes"
                values={ companyTypes.sort((a, b) => a.name.localeCompare(b.name)) }
                formValues={ formik.values.companyTypes }
                setFormValues={ values => { formik.setFieldValue('companyTypes', values); } }
                isInvalid={ formik.touched.companyTypes && !!formik.errors.companyTypes }
                labelPrefix="I am a "
                uniqueChoice={ true }
                component={ CheckBoxList } />

              <Field
                label="Website"
                name="website"
                isInvalid={ formik.touched.website && !!formik.errors.website }
                component={ TextField } />

              <Field
                label="Headquarter"
                placeholder="Address"
                name="headQuarter.address"
                isInvalid={ formik.touched.headQuarter?.address && !!formik.errors.headQuarter?.address }
                component={ TextField } />

              <Field
                placeholder="Zip code"
                name="headQuarter.zipCode"
                isInvalid={ formik.touched.headQuarter?.zipCode && !!formik.errors.headQuarter?.zipCode }
                component={ TextField } />

              <Field
                placeholder="City"
                name="headQuarter.city"
                isInvalid={ formik.touched.headQuarter?.city && !!formik.errors.headQuarter?.city }
                component={ TextField } />

              <Field
                placeholder="Country"
                name="headQuarter.country"
                isInvalid={ formik.touched.headQuarter?.country && !!formik.errors.headQuarter?.country }
                component={ TextField } />

              <Field
                label="Contact name"
                placeholder="Last name"
                name="contact.lastName"
                isInvalid={ formik.touched.contact?.lastName && !!formik.errors.contact?.lastName }
                component={ TextField } />

              <Field
                placeholder="First name"
                name="contact.firstName"
                isInvalid={ formik.touched.contact?.firstName && !!formik.errors.contact?.firstName }
                component={ TextField } />

              <Field
                placeholder="Email"
                type="email"
                name="contact.email"
                isInvalid={ formik.touched.contact?.email && !!formik.errors.contact?.email }
                disabled={ editing && !isadmin }
                component={ TextField } />

              <Field
                placeholder="Phone number"
                name="contact.phoneNumber"
                isInvalid={ formik.touched.contact?.phoneNumber && !!formik.errors.contact?.phoneNumber }
                component={ TextField } />

              <Field
                label="Brands"
                name="brands"
                isInvalid={ formik.touched.brands && !!formik.errors.brands }
                component={ TextField } />
            </Col>
          </Row>
          <Row>
            <Button type="reset" onClick={ formik.resetForm } className="btn btn-small btn-danger ml-auto mr-2">Reset</Button>
            <Button type="submit" className="btn btn-small btn-primary mr-auto ml-2">
              {
                formik.isSubmitting
                  ? <Loader size="sm" />
                  : 'Save'
              }
            </Button>
          </Row>
        </fieldset>
      </Form>
    </FormikProvider>
  );
};

export default MemberForm;
