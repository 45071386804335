import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, FormikProvider, Field } from 'formik';
import { Col, Form, Row, Button } from 'react-bootstrap';

import TextField from '../../UI/TextField/TextField';
import DateField from '../../UI/DateField/DateField';
import Loader from '../../UI/Loader/Loader';
import ValidationMessages from '../../../messages/ValidationMessages';
import ImageField from '../../UI/ImageField/ImageField';
import CheckBoxField from '../../UI/CheckBoxField/CheckBoxField';
import ValidationModal from '../../UI/ValidationModal/ValidationModale';

const MeetingForm = ({ mutation, data = {}, title, displayWarning = false }) => {
  const valuesToCheck = [
    { getData: x => new Date(x.startDate).toLocaleDateString() },
    { getData: x => new Date(x.endDate).toLocaleDateString() },
    { getData: x => x.address },
    { getData: x => x.zipCode },
    { getData: x => x.city },
    { getData: x => x.country }
  ];

  const [showValidationModal, useShowValidationModal] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(ValidationMessages.required).max(255, ValidationMessages.string.length.max255),
    subTitle: Yup.string().required(ValidationMessages.required).max(255, ValidationMessages.string.length.max255),
    startDate: Yup.date().required(ValidationMessages.required),
    endDate: Yup.date().required(ValidationMessages.required).when('startDate', (startDate, yup) => startDate && yup.min(startDate, ValidationMessages.date.end_before_start)),
    enabled: Yup.bool(),
    address: Yup.string().max(255, ValidationMessages.string.length.max255),
    zipCode: Yup.string().max(255, ValidationMessages.string.length.max255),
    city: Yup.string().max(255, ValidationMessages.string.length.max255),
    country: Yup.string().max(255, ValidationMessages.string.length.max255),
  });

  const formik = useFormik({
    initialValues: {
      id: data.id || null,
      title: data.title || '',
      subTitle: data.subTitle || '',
      startDate: data.startDate ? new Date(data.startDate).toISOString().split('T')[0] : '',
      endDate: data.endDate ? new Date(data.endDate).toISOString().split('T')[0] : '',
      enabled: data.enabled || false,
      picture: data.picture || null,
      address: data.address || '',
      zipCode: data.zipCode || '',
      city: data.city || '',
      country: data.country || '',
    },
    onSubmit: async values => {
      if (displayWarning) {
        if (valuesToCheck.some(x => x.getData(data) !== x.getData(values)) && values.enabled) {
          useShowValidationModal(true);
          return;
        }
      }
      return await mutation.mutateAsync(values);
    },
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={ formik }>
      <Form onSubmit={ formik.handleSubmit }>
        <fieldset disabled={ formik.isSubmitting }>
          <legend>{ title }</legend>
          <Row>
            <Col sm="12" md="4">
              <Field
                title={ formik.values.title }
                name="picture"
                image={ formik.values.picture }
                onImageLoaded={ readerResult => formik.setFieldValue('picture', readerResult) }
                component={ ImageField } />
            </Col>
            <Col sm="12" md="8">
              <Field
                label="Title"
                name="title"
                isInvalid={ formik.touched.title && !!formik.errors.title }
                component={ TextField } />

              <Field
                label="Subtitle"
                name="subTitle"
                isInvalid={ formik.touched.subTitle && !!formik.errors.subTitle }
                component={ TextField } />

              <Field
                label="Start date"
                name="startDate"
                isInvalid={ formik.touched.startDate && !!formik.errors.startDate }
                component={ DateField } />

                <Field
                  label="End date"
                  name="endDate"
                  isInvalid={ formik.touched.endDate && !!formik.errors.endDate }
                  component={ DateField } />

              <Field
                label="Enabled"
                name="enabled"
                type="switch"
                isInvalid={ formik.touched.enabled && !!formik.errors.enabled } component={ CheckBoxField } />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label="Location"
                placeholder="Address"
                name="address"
                isInvalid={ formik.touched.address && !!formik.errors.address }
                component={ TextField } />
              <Row>
                <Col sm="4">
                    <Field
                      placeholder="Zip code"
                      name="zipCode"
                      isInvalid={ formik.touched.zipCode && !!formik.errors.zipCode }
                      component={ TextField } />
                </Col>
                <Col sm="4">
                  <Field
                    placeholder="City"
                    name="city"
                    isInvalid={ formik.touched.city && !!formik.errors.city }
                    component={ TextField } />
                </Col>
                <Col sm="4">
                  <Field
                    placeholder="Country"
                    name="country"
                    isInvalid={ formik.touched.country && !!formik.errors.country }
                    component={ TextField } />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Button type="reset" onClick={ formik.resetForm } className="btn btn-small btn-danger ml-auto mr-2">Reset</Button>
            <Button type="submit" className="btn btn-small btn-primary mr-auto ml-2">
              {
                formik.isSubmitting
                  ? <Loader size="sm" />
                  : 'Save'
              }
            </Button>
          </Row>
        </fieldset>
      </Form>
      { showValidationModal && <ValidationModal
        title="Event modification"
        message="Some fields have been modified, an email will be send to all participants to inform them. Do you want to continue ?"
        hide={ () => useShowValidationModal(false) }
        confirmMutation={ mutation }
        confirmMutationValues={ formik.values } />
      }
    </FormikProvider>
  );
};

export default MeetingForm;
