const colors = {
  backgroundColor: '#f5f5fe',
  blue: '#3c80e3',
  fushia: '#e53167',
  black: '#151617',
  white: '#f5f5fe',
  gray: '#9b9b9b',
  darkGray: '#707070',
  blueSecondary: '#6c63ff',
  whiteCool: '#f7f7f7'
};

const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export {
  colors,
  breakpoints,
};
