// eslint-disable-next-line no-unused-vars
const oldNews = [
  {
    image: '/assets/images/News/polaris_2021_anuga.png',
    title: 'ANUGA 2021',
    description: 'From the 09th until the 13rd of October 2021 - POLARIS PAVILION  hall 4.2 – You want to join us?',
    additionalImages: [
      '/assets/images/News/polaris_2021_anuga_pavillon.jpg'
    ]
  },
];

const news = [
  {
    image: '/assets/images/News/polaris_2021_westdrift.jpg',
    title: 'POLARIS PACIFIC FROZEN FRUITS & VEGETABLES LOS ANGELES 2023',
    description: 'The 29th & 30th of November 2023<br />At the Westdrift Hotel in Manhattan Beach, for more info, <a alt="Contact us" href="/contact-us">contact us</a>...'
  },
  {
    image: '/assets/images/News/Colisee.jpg',
    title: 'POLARIS NUTS & DRIED FRUITS ROME 2024',
    description: 'The 14th & 15th of March 2024<br />At the A.Roma Lifestyle Hotel in Rome, for more info, <a alt="Contact us" href="/contact-us">contact us</a>...'
  },
  {
    image: '/assets/images/News/polaris_2021_anuga.png',
    title: 'ANUGA 2023',
    description: '7th to the 11th of October 2023<br />We will be there : Stand D 069G – E060G &  D060G, for more info, <a alt="Contact us" href="/contact-us">contact us</a>...'
  },
  {
    image: '/assets/images/News/Hotel_Regina_Palace.jpg',
    title: 'POLARIS EUROPE FROZEN FRUITS & VEGETABLES LAKE MAGGIORE 2024',
    description: 'The 10th & 11th of April 2024<br />At the Regina Palace Hotel in Stresa, Lake Maggiore, for more info, <a alt="Contact us" href="/contact-us">contact us</a>...'
  },
];

export default news;
