const endpoints = {
  Token: 'token',
  Users: 'users',
  Activities: 'activities',
  CompanyTypes: 'company_types',
  BusinessAreas: 'business_areas',
  BusinessPartners: 'business_partners',
  ProductRange: 'products',
  Feedbacks: 'feedback',
  Contacts: 'contacts',
  ResetPasswordTokens: 'reset_password_tokens',
  Events: 'events',
  EventUsers: 'event_users',
  ContactUs: 'contact_us',
  MediaObjects: 'media_objects',
};

export default endpoints;
