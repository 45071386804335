import { useFormik } from 'formik';
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Loader from '../Loader/Loader';

const ValidationModal = ({
  title,
  message,
  hide = () => {},
  confirmMutation,
  confirmMutationValues,
}) => {
  const formik = useFormik({
    initialValues: confirmMutationValues,
    enableReinitialize: true,
    onSubmit: async values => {
      await confirmMutation.mutateAsync(values);
      hide();
    }
  });

  return (
    <Form onSubmit={ formik.handleSubmit }>
      <Modal
        backdrop="static"
        show={ true }
        onHide={ hide }>
        <Modal.Header closeButton={ !formik.isSubmitting }>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>{ message }</Modal.Body>
        <Modal.Footer>
          <Button type="button" disabled={ formik.isSubmitting } onClick={ hide } className="btn btn-small btn-danger ml-auto mr-2">Cancel</Button>
          <Button type="submit" className="btn btn-small btn-primary mr-auto ml-2" disabled={ formik.isSubmitting } onClick={ formik.submitForm }>
            {
              formik.isSubmitting
                ? <Loader size="sm" />
                : 'Confirm'
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default ValidationModal;
