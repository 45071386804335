import React from 'react';

import { useGetMedia, useGetMediaContent } from '../../../http';
import Loader from '../../UI/Loader/Loader';

const MeetingMediaObject = ({
  mediaId,
  mediaComponent = () => {}
}) => {
  const { isLoading, data: media = {} } = useGetMedia(mediaId);
  const { isLoading: isLoadingContent, data: mediaContent = '' } = useGetMediaContent(media.contentUrl);

  if (isLoading || isLoadingContent) {
    return <Loader />;
  }

  return React.cloneElement(mediaComponent, {
    src: mediaContent,
    fileName: media.contentUrl.replace(/\/media\/[0-9a-f]+_/i, ''),
  });
};

export default MeetingMediaObject;
