import React from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { useGetContactFromEmail, useResetPasswordToken } from '../../http';
import ValidationMessages from '../../messages/ValidationMessages';
import TextField from '../UI/TextField/TextField';
import Loader from '../UI/Loader/Loader';

const PasswordLost = () => {
  const resetPasswordToken = useResetPasswordToken();
  const requestResetPasswordToken = useGetContactFromEmail(async res => {
    if (res['hydra:totalItems'] > 0) {
      return await resetPasswordToken.mutateAsync(res['hydra:member'][0].user);
    }

    toast.error('Email address unknown');
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(ValidationMessages.string.invalidEmail).required(ValidationMessages.required)
    }),
    onSubmit: async values => await requestResetPasswordToken(values.email)
  });

  return (
    <FormikProvider value={ formik }>
      <form onSubmit={ formik.handleSubmit }>
        <fieldset disabled={ formik.isSubmitting }>
          <legend>Password lost</legend>
          <Field label="Email" name="email" className="w-50" component={ TextField } />
          <Button type="reset" className="btn btn-danger mr-2" onClick={ () => formik.resetForm() }>Reset</Button>
          { formik.isSubmitting
            ? <Button disabled className="btn btn-primary"><Loader size="sm" /></Button>
            : <Button type="submit" className="btn btn-primary">Recover password</Button>
          }
        </fieldset>
      </form>
    </FormikProvider>
  );
};

export default PasswordLost;
