import { useQuery } from 'react-query';

import http from '../helpers/http';
import endpoints from './endpoints';

const getProductRange = () => http.get(`${ endpoints.ProductRange }?pagination=false`);

const useGetProductRange = () => useQuery('product_range', () => getProductRange(), { select: x => x['hydra:member'] });

export {
  useGetProductRange,
};
