import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import http from '../helpers/http';
import endpoints from './endpoints';

const getFeedbacks = () => http.get(`${ endpoints.Feedbacks }?pagination=false`);
const createFeedback = feedback => http.post(`${ endpoints.Feedbacks }`, feedback);
const updateFeedback = feedback => http.put(`${ endpoints.Feedbacks }/${ feedback.id }`, feedback);
const deleteFeedback = feedback => http.delete(`${ endpoints.Feedbacks }/${ feedback.id }`);

const useGetFeedbacks = () => useQuery('feedbacks', () => getFeedbacks(), { select: x => x['hydra:member'] });

const useCreateFeedback = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(createFeedback, {
    onSuccess: () => {
      toast.success('Feedback created');
      queryClient.invalidateQueries('feedbacks');
      queryClient.invalidateQueries('events');
      onSuccess();
    },
  });
};

const useUpdateFeedback = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateFeedback, {
    onSuccess: () => {
      toast.success('Feedback modified');
      queryClient.invalidateQueries('feedbacks');
      queryClient.invalidateQueries('events');
      onSuccess();
    },
  });
};

const useDeleteFeedback = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(deleteFeedback, {
    onSuccess: () => {
      toast.success('Feedback deleted');
      queryClient.invalidateQueries('feedbacks');
      queryClient.invalidateQueries('events');
      onSuccess();
    },
  });
};

export {
  useGetFeedbacks,
  useCreateFeedback,
  useUpdateFeedback,
  useDeleteFeedback,
};
