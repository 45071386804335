const ValidationMessages = {
  required: 'This field is mandatory',
  string: {
    invalidEmail: 'The email format is invalid',
    length: {
      max180: 'Maximum 180 characters',
      max255: 'Maximum 255 characters',
      max2000: 'Maximum 2000 characters',
      min20: 'Minimum 20 characters',
    },
  },
  date: {
    end_before_start: 'End date cannot be before start date'
  }
};

export default ValidationMessages;
