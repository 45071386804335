import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import * as actions from '../../store/actions';
import Footer from '../../components/Footer/Footer';
import Header from './Header/Header';
import { breakpoints } from '../../constants/css';

const DivWithWave = styled.div`
  ${ props => props.showwave && `
    background: url('/assets/images/Waves/from-top.svg') no-repeat;
    background-position: top -340px right -100px;

    @media(max-width: ${ breakpoints.lg }) {
      background: none;
    }
  ` }
`;

const MainLayout = props => {
  const location = useLocation();

  const showwave = location.pathname === '/';

  if (props.isAuthenticated && props.redirectPath) {
    props.setRedirectPath(null);
    return <Redirect to={ props.redirectPath } />;
  }

  return (
    <DivWithWave showwave={ showwave }>
      <Header showwave={ showwave } />
      <Container as="main" className={ showwave ? 'p-0' : 'p-3' } fluid={ showwave }>
        { props.children }
      </Container>
      <Footer showwave={ showwave } />
    </DivWithWave>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    redirectPath: state.auth.redirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRedirectPath: redirectPath => dispatch(actions.authSetRedirectPath(redirectPath)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
