import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { RiAddLine, RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import { connect } from 'react-redux';

import roles from '../../constants/roles';
import GuardedElement from '../../hoc/GuardedElement/GuardedElement';
import { useCreateFeedback, useDeleteFeedback, useGetFeedbacks, useUpdateFeedback } from '../../http';
import Loader from '../UI/Loader/Loader';
import ValidationModal from '../UI/ValidationModal/ValidationModale';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import { feedback } from './Feedbacks.module.scss';

const Feedbacks = () => {
  const { isLoading, data = [] } = useGetFeedbacks();
  const [feedbackModalData, setFeedbackModalData] = useState(null);
  const [feedbackMutation, setFeedbackMutation] = useState(null);
  const [showCreateUpdateModal, setShowCreateUpdateModal] = useState(false);
  const [showDeleteFeedback, setShowDeleteFeedback] = useState(false);

  const clearState = () => {
    setShowDeleteFeedback(false);
    setShowCreateUpdateModal(false);
    setFeedbackModalData(null);
    setFeedbackMutation(null);
  };

  const createFeedback = useCreateFeedback(clearState);
  const updateFeedback = useUpdateFeedback(clearState);
  const deleteFeedback = useDeleteFeedback(clearState);

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return (
    <>
      <h1 className="text-uppercase d-flex flex-row">
        <div className="bordered d-inline-block">
          Feedbacks
        </div>
        <GuardedElement roles={ [roles.admin] }>
          <div className="ml-auto">
            <Button className="btn btn-small" onClick={ () => {
              setFeedbackMutation(createFeedback);
              setFeedbackModalData({});
              setShowCreateUpdateModal(true);
            } }>
              <RiAddLine />
            </Button>
          </div>
        </GuardedElement>
      </h1>
      <div className="d-flex flex-column mt-5">
        { data.map(f =>
          <figure key={ f.id } className={ feedback }>
            <blockquote>
              <GuardedElement roles={ [roles.admin] }>
                  <RiPencilLine className="btn-link mr-2" onClick={ () => {
                    setFeedbackMutation(updateFeedback);
                    setFeedbackModalData({ ...f, event: f.event?.['@id'] });
                    setShowCreateUpdateModal(true);
                  } } />
                  <RiDeleteBinLine className="btn-link mr-2" onClick={ () => {
                    setFeedbackMutation(deleteFeedback);
                    setFeedbackModalData({ id: f.id });
                    setShowDeleteFeedback(true);
                  } } />
                </GuardedElement>
                { f.content }
            </blockquote>
            <hr />
          </figure>
        ) }
      </div>

      { showCreateUpdateModal &&
        <FeedbackForm
          data={ feedbackModalData }
          mutation={ feedbackMutation }
          isShown={ feedbackModalData !== undefined }
          canSelectEvent={ true }
          isEditing={ feedbackModalData.id }
          hide={ clearState } />
      }

      { showDeleteFeedback &&
        <ValidationModal
          title="Feedback deletion"
          message="Are you sure you want to delete this feedback ?"
          hide={ clearState }
          confirmMutation={ feedbackMutation }
          confirmMutationValues={ feedbackModalData } />
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.roles.includes(roles.admin),
  };
};

export default connect(mapStateToProps)(Feedbacks);
