import React, { useState } from 'react';
import { RiForbidLine, RiSearchLine, RiSubtractLine, RiUserLocationLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import roles from '../../../constants/roles';

import { getEventUsersExport, useGetEventUsers, useRemoveEventUser, useGetEvent } from '../../../http';
import CustomTable from '../../UI/CustomTable/CustomTable';
import dateHelper from '../../../helpers/date';
import Loader from '../../UI/Loader/Loader';
import ValidationModal from '../../UI/ValidationModal/ValidationModale';
import MeetingParticipantsAdd from './MeetingParticipantsAdd/MeetingParticipantsAdd';
import MeetingParticipantStandForm from './MeetingParticipantStandForm/MeetingParticipantStandForm';

const getActionsAccessorParticipants = ({
  meetingId,
  setRemoveUserMutationData,
  canChangeParticipants,
  setStandModalData,
}) => function actionsAccessorsParticipants (row) {
  return (
    <div className="table-actions">
      { canChangeParticipants
        ? <>
            <RiSubtractLine
              title="Remove member from meeting"
              onClick={ () => setRemoveUserMutationData({
                eventId: meetingId,
                userId: row.user.id
              }) } />
              <RiUserLocationLine
                title="Edit user booth"
                onClick={ () => setStandModalData({
                  eventUserId: row.id,
                  boothNumber: row.boothNumber,
                }) } />
            </>
        : <RiForbidLine
            title="Can't perform this action because the meeting is finished."
            className="text-muted" />
      }
      <Link to={ `/members/${ row.user.id }` }>
        <RiSearchLine title="Go to member profile" />
      </Link>
    </div>
  );
};

const getActionsAccessorForUsers = row => (
  <div className="text-center">
    <Link to={ `/members/${ row.user.id }` }>
      <RiSearchLine />
    </Link>
  </div>
);

const MeetingParticipants = ({
  meetingId,
  canChangeParticipants = false,
  isAdmin
}) => {
  const { isLoading, data: participants = [] } = useGetEventUsers(meetingId);

  const removeUserFromMeeting = useRemoveEventUser();

  const [removeUserMutationData, setRemoveUserMutationData] = useState(null);

  const [standModalData, setStandModalData] = useState(null);

  const { data: meeting = {} } = useGetEvent(meetingId);

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return (
    <>
      <h4 className="text-uppercase">Participants</h4>
        { isAdmin
          ? <>
              <CustomTable
                columns={ [
                  { id: 'company', Header: 'Company', accessor: 'user.company', filter: 'includes' },
                  { id: 'type', Header: 'Type', accessor: row => row.user.companyTypes?.map(x => x.name).join(', ') },
                  { id: 'contact', Header: 'Contact', accessor: row => `${ row.user.contact?.lastName || '' } ${ row.user.contact?.firstName || '' }${ row.user.contact?.email ? ` (${ row.user.contact.email })` : null }` },
                  { id: 'booth', Header: 'Booth', accessor: 'boothNumber' },
                  { id: 'date', Header: 'Date added', accessor: row => dateHelper.formatEnglishDateWithOrdinal(new Date(row.dateAdded)) },
                  {
                    id: 'actions',
                    Header: <div className="text-center">Actions</div>,
                    accessor: getActionsAccessorParticipants({
                      meetingId,
                      setRemoveUserMutationData,
                      canChangeParticipants,
                      setStandModalData,
                    }),
                    disableGlobalFilter: true,
                    disableSortBy: true,
                  },
                ] }
                data={ participants }
                exportable
                exportSelectId={ row => row['@id'] }
                exportFn={ getEventUsersExport }
                additionalFieldsToWatch={ [canChangeParticipants] }
                searchPlaceholder="Search a company, contact, etc..."
                sortBy= { { id: 'company', desc: false } } />
              { canChangeParticipants && <MeetingParticipantsAdd
                meetingId={ meetingId }
                participants={ participants } />
              }

              { removeUserMutationData &&
                <ValidationModal
                  title="Remove user from meeting"
                  message="Are you sure you want to remove this user from the meeting ?"
                  hide={ () => setRemoveUserMutationData(null) }
                  confirmMutation={ removeUserFromMeeting }
                  confirmMutationValues={ removeUserMutationData } />
              }
            </>
          : <CustomTable
              columns={ [
                { id: 'company', Header: 'Company', accessor: 'user.company', filter: 'includes' },
                { id: 'type', Header: 'Type', accessor: row => row.user.companyTypes?.map(x => x.name).join(', ') },
                { id: 'contact', Header: 'Contact', accessor: row => `${ row.user.contact?.lastName || '' } ${ row.user.contact?.firstName || '' }${ row.user.contact?.email ? ` (${ row.user.contact.email })` : null }` },
                { id: 'booth', Header: 'Booth', accessor: 'boothNumber' },
                { id: 'date', Header: 'Date added', accessor: row => dateHelper.formatEnglishDateWithOrdinal(new Date(row.dateAdded)) },
                { id: 'actions', Header: 'Actions', accessor: getActionsAccessorForUsers, disableGlobalFilter: true }
              ] }
              data={ new Date() > new Date(new Date(meeting.startDate) - (42 * 24 * 60 * 60 * 1000)) ? participants : [] }
              exportable
              exportSelectId={ row => row['@id'] }
              exportFn={ getEventUsersExport }
              showSearch={ false }
              sortBy= { { id: 'company', desc: false } } />
        }
      <MeetingParticipantStandForm
        data={ standModalData }
        hide={ () => setStandModalData(null) }
        />
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.roles.includes(roles.admin),
  };
};

export default connect(mapStateToProps)(MeetingParticipants);
