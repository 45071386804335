import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldArray, ErrorMessage } from 'formik';

import ErrorField from '../ErrorField/ErrorField';

const CheckBoxList = ({
  field,
  values,
  labelPrefix,
  uniqueChoice = false,
  formValues = [],
  setFormValues = () => {},
  ...props
}) => (
  <Form.Group>
    { props.label && <Form.Label>{ props.label }</Form.Label> }
    <FieldArray
      field
      props
      render={ () => (
        <div>
          { values.map(value => (
            <div key={ value.id }>
              <label className="mb-0">
                <input
                  name={ field.name }
                  type={ uniqueChoice ? 'radio' : 'checkbox' }
                  value={ value['@id'] }
                  checked={ formValues.includes(value['@id']) }
                  onChange={ () => {
                    if (formValues.includes(value['@id'])) {
                      setFormValues(formValues.filter(x => x !== value['@id']));
                    } else {
                      setFormValues(uniqueChoice ? [value['@id']] : [...formValues, value['@id']]);
                    }
                  } }
                />{ ' ' }
                { labelPrefix ? `${ labelPrefix }${ value.name.toLowerCase() }` : value.name }
              </label>
            </div>
          )) }
        </div>
      ) }
    />
    <ErrorMessage name={ field.name } component={ ErrorField } />
  </Form.Group>
);

export default CheckBoxList;
