/* eslint-disable no-return-assign */
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { RiAddLine, RiDeleteBinFill } from 'react-icons/ri';
import styled from 'styled-components';

import roles from '../../../constants/roles';
import GuardedElement from '../../../hoc/GuardedElement/GuardedElement';
import { useDeleteMedia } from '../../../http';
import Loader from '../../UI/Loader/Loader';
import ValidationModal from '../../UI/ValidationModal/ValidationModale';
import MeetingMediaObject from '../MeetingMediaObject/MeetingMediaObject';

const FileButton = styled(Button)`
  input[type=file] {
    display: none;
  }
`;

const StyledDeletionButton = styled(RiDeleteBinFill)`
  position: absolute;
  top: 2px;
  right: 2px;
  color: red;
  cursor: pointer;
`;

const MeetingMedias = ({
  title,
  meetingId,
  medias = [],
  bordered,
  actions = true,
  addMutation = () => {},
  component = () => {},
  acceptFiles = '',
  ...props
}) => {
  const fileInputRef = useRef(null);
  const [showDeleteMediaModal, setShowDeleteMediaModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const deleteMutation = useDeleteMedia(meetingId, () => setShowDeleteMediaModal(false));

  return (
    <div { ...props }>
      <h4 className="text-uppercase d-flex flex-row">
        <div className={ bordered && 'bordered d-inline-block' }>{ title }</div>
        { medias.length < 50 &&
          <GuardedElement roles={ [actions && roles.admin] }>
            <Formik
              initialValues={ { file: null } }
              onSubmit={ values => addMutation.mutateAsync(values.file) }>
                { formik =>
                  <Form onSubmit={ formik.handleSubmit } className="ml-auto">
                    { formik.isSubmitting
                      ? <Loader />
                      : <FileButton className="btn btn-small" onClick={ () => fileInputRef.current.click() }>
                          <RiAddLine />
                          <input type="file" accept={ acceptFiles } ref={ fileInputRef } onChange={ ev => { formik.setFieldValue('file', ev.target.files[0]); formik.submitForm(); } } />
                        </FileButton>
                    }
                  </Form>
                }
            </Formik>
          </GuardedElement>
        }
      </h4>
      <div className="d-flex flex-row flex-wrap align-items-start">
        { medias.map(x =>
          <div key={ x.id } className="position-relative">
            <MeetingMediaObject
              mediaId={ x.id }
              mediaComponent={ component } />
            <GuardedElement roles={ [actions && roles.admin] }>
              <Formik
                initialValues={ {
                  id: x.id
                } }
                onSubmit={ values => { setShowDeleteMediaModal(true); setModalData(values); } }>
                  { formik =>
                    <Form onSubmit={ formik.handleSubmit }>
                      <StyledDeletionButton onClick={ () => formik.submitForm() } />
                    </Form>
                  }
                </Formik>
            </GuardedElement>
          </div>) }
      </div>
      { showDeleteMediaModal &&
        <ValidationModal
          title="Media deletion"
          message="Are you sure you want to delete this media ?"
          hide={ () => setShowDeleteMediaModal(false) }
          confirmMutation={ deleteMutation }
          confirmMutationValues={ modalData } />
      }
    </div>
  );
};

export default MeetingMedias;
