import { Field, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useUpdateEventUser } from '../../../../http';
import Loader from '../../../UI/Loader/Loader';
import TextField from '../../../UI/TextField/TextField';

const MeetingParticipantStandForm = ({
  data,
  hide = () => {},
}) => {
  const mutation = useUpdateEventUser(data?.eventUserId);

  const formik = useFormik({
    initialValues: {
      boothNumber: data?.boothNumber,
    },
    onSubmit: async values => {
      await mutation.mutateAsync(values);
      hide();
    },
    enableReinitialize: true,
  });

  return (
    <Modal show={ data !== null } onHide={ hide }>
      <FormikProvider value={ formik }>
        <form onSubmit={ formik.handleSubmit }>
          <fieldset disabled={ formik.isSubmitting }>
            <Modal.Header closeButton>
              Define user stand for this event
            </Modal.Header>
            <Modal.Body>
              <Field
                placeholder="Stand"
                name="boothNumber"
                isInvalid={ formik.touched.boothNumber && !!formik.errors.boothNumber }
                component={ TextField } />
            </Modal.Body>
            <Modal.Footer>
              <Button type="reset" onClick={ formik.resetForm } className="btn btn-small btn-danger ml-auto mr-2">Reset</Button>
              <Button type="submit" className="btn btn-small btn-primary mr-auto ml-2">
                {
                  formik.isSubmitting
                    ? <Loader size="sm" />
                    : 'Save'
                }
              </Button>
            </Modal.Footer>
          </fieldset>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default MeetingParticipantStandForm;
