import { useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import http from '../helpers/http';
import endpoints from './endpoints';

const createContactUs = contactUs => http.post(endpoints.ContactUs, contactUs);

const useCreateContactUs = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(createContactUs, {
    onSuccess: () => {
      toast.success('Message sent');
      queryClient.invalidateQueries('users');
      onSuccess();
    },
  });
};

export {
  useCreateContactUs
};
