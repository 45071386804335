import React from 'react';
import { connect } from 'react-redux';

import { useGetUser } from '../../../http';
import Loader from '../../UI/Loader/Loader';
import roles from '../../../constants/roles';
import MemberDetailsMeetings from './MemberDetailsMeetings/MemberDetailsMeetings';
import MemberDetailsProfile from './MemberDetailsProfile/MemberDetailsProfile';

const MemberDetails = ({
  userId,
  ...props
}) => {
  const { isLoading, data = {} } = useGetUser(userId);

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return (
    <>
      <MemberDetailsProfile data={ data } { ...props } />
      { (props.isAdmin || props.loggedUserId === userId) &&
        <MemberDetailsMeetings userId={ data['@id'] } />
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.roles.includes(roles.admin),
    loggedUserId: state.auth.id,
  };
};

export default connect(mapStateToProps)(MemberDetails);
