import React from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetResetPasswordValidity, useResetPassword } from '../../http';
import ValidationMessages from '../../messages/ValidationMessages';
import Loader from '../UI/Loader/Loader';
import TextField from '../UI/TextField/TextField';

const ResetPassword = ({ history, match: { params: { tokenId } } }) => {
  const { isLoading, data: validity } = useGetResetPasswordValidity(tokenId);

  const resetPassword = useResetPassword(() => history.push('/'));

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(ValidationMessages.required),
      repeatPassword: Yup.string().test('match', ValidationMessages.string.passwordsDifferent, function (x) { return x === this.parent.password; }),
    }),
    onSubmit: async values => await resetPassword.mutateAsync({ tokenId, values })
  });

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  const isValidToken = new Date() <= new Date(validity);

  return (
    <FormikProvider value={ formik }>
      <form onSubmit={ formik.handleSubmit }>
        <fieldset disabled={ formik.isSubmitting || !isValidToken }>
          <legend>Reset your password</legend>
          { !isValidToken
            ? <div className="text-danger">
                The password reset token is expired, please go either to <Link to="/password-lost">Password lost ?</Link> page or to your profile page to get a new reset link.
              </div>
            : null
          }
          <Field label="New password" name="password" type="password" className="w-50" component={ TextField } />
          <Field label="Repeat password" name="repeatPassword" type="password" className="w-50" component={ TextField } />
          <Button type="reset" className="btn btn-danger mr-2" onClick={ () => formik.resetForm() }>Reset</Button>
          { formik.isSubmitting
            ? <Button disabled className="btn btn-primary"><Loader size="sm" /></Button>
            : <Button type="submit" className="btn btn-primary">Update password</Button>
          }
        </fieldset>
      </form>
    </FormikProvider>
  );
};

export default ResetPassword;
