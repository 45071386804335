import { React, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { RiSearchLine } from 'react-icons/ri';
import styled from 'styled-components';

const WhiteSearch = styled(InputGroup)`
  &, * {
    background-color: white !important;
    border: none;
    border-radius: 10px !important;
  }
`;

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  disabled,
  placeholder
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = value => {
    setGlobalFilter(value || undefined);
  };

  return (
    <WhiteSearch className="w-50">
      <FormControl
        placeholder={ placeholder }
        aria-label={ placeholder }
        aria-describedby="searchIcon"
        disabled={ disabled }
        value={ value || '' }
        onChange={ e => {
          setValue(e.target.value);
          onChange(e.target.value);
        } } />
        <InputGroup.Append>
          <InputGroup.Text id="searchIcon"><RiSearchLine /></InputGroup.Text>
        </InputGroup.Append>
    </WhiteSearch>
  );
};

export default GlobalFilter;
