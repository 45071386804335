import { useQuery } from 'react-query';

import http from '../helpers/http';
import endpoints from './endpoints';

const getActivities = () => http.get(`${ endpoints.Activities }?pagination=false`);

const useGetActivities = () => useQuery('activities', () => getActivities(), { select: x => x['hydra:member'] });

export {
  useGetActivities
};
