import { ErrorMessage } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

import ErrorField from '../ErrorField/ErrorField';

const DateField = ({
  field,
  ...props
}) => (
  <Form.Group>
    { props.label && <Form.Label>{ props.label }</Form.Label> }
    <Form.Control
      type="date"
      placeholder={ props.placeholder || props.label }
      { ...props }
      { ...field } />
    <ErrorMessage name={ field.name } component={ ErrorField } />
  </Form.Group>
);

export default DateField;
