import * as actionTypes from '../actions/actionTypes';

const getInitialState = () => {
  const localStorageAuth = JSON.parse(localStorage.getItem('auth')) || {};
  return {
    token: localStorageAuth.token || null,
    username: localStorageAuth.username || null,
    roles: localStorageAuth.roles || [],
    id: localStorageAuth.id || null,
    userId: localStorageAuth.userId || null,
    redirectPath: localStorageAuth.redirectPath || null,
  };
};

const authSuccess = (state, action) => {
  return {
    ...state,
    token: action.token,
    username: action.username,
    roles: action.roles,
    id: action.id,
    userId: action.userId,
  };
};

const authLogout = (state, action) => {
  return getInitialState();
};

const authSetRedirectPath = (state, action) => {
  return {
    ...state,
    redirectPath: action.path,
  };
};

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_SET_REDIRECT_PATH: return authSetRedirectPath(state, action);
    default: return state;
  }
};

export default reducer;
