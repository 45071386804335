import { connect } from 'react-redux';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { useGetUser, useUpdateUser } from '../../../http';
import Loader from '../../UI/Loader/Loader';
import MemberForm from '../MemberForm/MemberForm';
import roles from '../../../constants/roles';

const MemberEdit = ({
  match: { params: { userId } },
  isAdmin,
  loggedUserId,
}) => {
  const mutation = useUpdateUser();

  const { isLoading, data } = useGetUser(userId);

  if (loggedUserId !== userId && !isAdmin) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return <div className="text-center"><Loader /></div>;
  }

  return <MemberForm mutation={ mutation } data={ data } title="Edit member" editing={ true } isadmin={ isAdmin }/>;
};

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.roles.includes(roles.admin),
    loggedUserId: state.auth.id,
  };
};

export default connect(mapStateToProps)(MemberEdit);
