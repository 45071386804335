import React from 'react';
import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';

import ErrorField from '../ErrorField/ErrorField';

const SelectField = ({
  field,
  values,
  selectKey,
  selectLabel,
  nullable,
  ...props
}) => (
    <Form.Group>
      { props.label && <Form.Label>{ props.label }</Form.Label> }
      <Form.Control
        as="select"
        custom
        { ...props }
        { ...field }>
          <option value="" disabled={ !nullable }>Select an option</option>
          { values.map(x => <option key={ selectKey?.(x) || x.id } value={ selectKey?.(x) || x.id }>{ selectLabel?.(x) || x.name }</option>) }
        </Form.Control>
      <ErrorMessage name={ field.name } component={ ErrorField } />
    </Form.Group>
);

export default SelectField;
