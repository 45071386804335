import http from '../helpers/http';
import endpoints from './endpoints';

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';

const getUser = userId => http.get(`${ endpoints.Users }/${ userId }`);
const getUsers = () => http.get(endpoints.Users);
const getUsersExport = ids => http.post(`${ endpoints.Users }/export`, { users: ids });

const createUser = user => http.post(endpoints.Users, user);
const updateUser = user => http.put(`${ endpoints.Users }/${ user.id }`, user);
const deleteUser = userId => http.delete(`${ endpoints.Users }/${ userId }`);

const useGetUser = (userId) => useQuery(['users', { id: userId }], () => getUser(userId));

const useGetUsers = () => useQuery('users', () => getUsers(), { keepPreviousData: true });

const useCreateUser = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(createUser, {
    onSuccess: () => {
      toast.success('Member created');
      queryClient.invalidateQueries('users');
      onSuccess();
    },
  });
};

const useUpdateUser = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: () => {
      toast.success('Member modified');
      queryClient.invalidateQueries('users');
      onSuccess();
    },
  });
};

const useUpdateUserAvatar = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: () => {
      toast.success('Picture modified');
      queryClient.invalidateQueries('users');
      onSuccess();
    },
  });
};

const useDeleteUser = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: () => {
      toast.success('Member deleted');
      queryClient.invalidateQueries('users');
      onSuccess();
    },
  });
};

export {
  useGetUser,
  useGetUsers,
  useCreateUser,
  useUpdateUser,
  useUpdateUserAvatar,
  useDeleteUser,
  getUsersExport,
};
